<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <button
                    class="btn-shadow btn btn-dark btn-sm dense"
                    @click="toggleDropdown"
                >
                    Columns
                </button>
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <v-btn
                @click="
                    openDistributionModal({ id: null, page: 1, create: true })
                "
                color="primary"
                size="small"
            >
                <v-icon left>mdi-plus</v-icon> Create Distribution
            </v-btn>
        </div>
        <ul class="nav nav-tabs mt-3 mb-1" id="tableTabs" role="tablist">
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :class="{
                        active: activeTab === 'all',
                    }"
                    id="all-tab"
                    type="button"
                    @click="activeTab = 'all'"
                >
                    All
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :class="{
                        active: activeTab === 'country',
                    }"
                    id="country-tab"
                    type="button"
                    @click="activeTab = 'country'"
                >
                    Country
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button
                    class="nav-link"
                    :class="{
                        active: activeTab === 'publisher',
                    }"
                    id="publisher-tab"
                    type="button"
                    @click="activeTab = 'publisher'"
                >
                    Publisher
                </button>
            </li>
        </ul>
        <!-- Table displayed based on the active tab -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <!-- Table for Country -->
            <Vue3Datatable
                height="100%"
                v-if="activeTab === 'country'"
                :columns="countryColumns"
                :rows="rows"
                sortColumn="updated_at"
                sortDirection="desc"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <template #country_name="{ value }">
                    <div class="d-flex items-center gap-2">
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            @error="handleFlagImageError"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>
                <!-- Distribution Button -->
                <template #actions="{ value }">
                    <button
                        @click="toggleDistribution(value.country_id)"
                        class="btn btn-sm btn-primary"
                    >
                        Distribution
                    </button>
                </template>
            </Vue3Datatable>
            <!-- Table for Publisher -->
            <Vue3Datatable
                height="100%"
                v-if="activeTab === 'publisher'"
                :columns="publisherColumns"
                sortColumn="updated_at"
                sortDirection="desc"
                :rows="rows"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- Distribution Button -->
                <template #actions="{ value }">
                    <button
                        @click="toggleDistribution(value.country_id)"
                        class="btn btn-sm btn-primary"
                    >
                        Distribution
                    </button>
                </template>
            </Vue3Datatable>
            <!-- DataTable for All -->
            <Vue3Datatable
                height="100%"
                v-if="activeTab === 'all'"
                :columns="allColumns"
                sortColumn="updated_at"
                sortDirection="desc"
                :rows="rows"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <template #is_active_open="{ value }">
                    <div class="flex gap-4">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="circle"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="mr-1 fa-xs margin_top_1 svg-inline--fa fa-circle fa-w-16"
                            :class="
                                value.is_active_open === 'true'
                                    ? 'text-success'
                                    : 'text-danger'
                            "
                            style="width: 1rem; height: 1rem"
                        >
                            <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                            ></path>
                        </svg>
                    </div>
                </template>
                <!-- Advertiser Name -->
                <template #advertiser_name="{ value }">
                    <a
                        :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.advertiser_name }}
                        ({{ value.advertiser_id }})
                    </a>
                </template>
                <!-- Country -->
                <template #country_name="{ value }">
                    <div class="d-flex items-center gap-2">
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            @error="handleFlagImageError"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <v-btn
                            icon
                            color="warning"
                            variant="outlined"
                            size="small"
                            @click="
                                openDistributionModal({
                                    id: value.id,
                                    page: 2,
                                    create: false,
                                })
                            "
                        >
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="success"
                            variant="outlined"
                            size="small"
                            @click="
                                openDistributionModal({
                                    id: value.id,
                                    page: 1,
                                    create: false,
                                })
                            "
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="info"
                            variant="outlined"
                            size="small"
                            @click="
                                openDistributionModal({
                                    id: value.id,
                                    page: 1,
                                    create: true,
                                })
                            "
                        >
                            <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            color="error"
                            variant="outlined"
                            size="small"
                            @click="deleteLeads(value.id)"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </template>
            </Vue3Datatable>
            <!-- <Vue3Datatable height="100%"
          v-if="nestedRow.length > 0"
          :columns="allColumns"
          :rows="nestedRow"
          :sortable="true"
          :stickyHeader="true"
          :loading="loading"
          skin="bh-table-bordered bh-table-responsive "
        >
        </Vue3Datatable> -->
            <!-- <div
                class="modal fade"
                id="editModal"
                tabindex="-1"
                aria-labelledby="editModalLabel"
                aria-hidden="true"
                ref="editModal"
            >
                <EditComponent :editId="editId" />
            </div> -->
            <!-- <div
                class="modal fade"
                id="createModal"
                tabindex="-1"
                aria-labelledby="creatModalLabel"
                aria-hidden="true"
                ref="createModal"
            >
                <CreateComponent />
            </div> -->
        </div>
    </div>
    <CreateDistributionModal
        v-if="isCreateDistributionModal"
        @close="isCreateDistributionModal = false"
        :pageNumber="distributionModalPage"
        :distributionId="distributionUpdateId"
        :distributionData="distributionData"
    />
</template>
<script>
import { ref, computed, onMounted, nextTick } from "vue";
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
// import { Modal } from "bootstrap";
// import EditComponent from "./EditComponent.vue";
// import CreateComponent from "./CreateComponent.vue";
import CreateDistributionModal from "./CreateDistributionModal.vue";
import { fetchDistributionById } from "./service";
import { useStore } from "vuex";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
        // EditComponent,
        // CreateComponent,
        CreateDistributionModal,
    },
    props: {
        filterData: { type: Object, default: () => ({}) },
    },
    setup() {
        const loading = ref(false);
        const store = useStore();
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);
        const allColumns = ref([
            {
                field: "is_active_open",
                title: "",
                visible: true,
            },
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                field: "type",
                title: "Method",
                visible: true,
                sortable: true,
            },
            {
                field: "timezone_name",
                title: "Timezone",
                visible: true,
                sortable: true,
            },
            {
                field: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                field: "updated_at",
                title: "Updated",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);
        // const editModal = ref(null);
        // const createModal = ref(null);
        const editId = ref(null);
        // start distribution
        const isCreateDistributionModal = ref(false);
        const distributionModalPage = ref(1);
        const distributionUpdateId = ref(null);
        const distributionData = ref(null);
        async function openDistributionModal({ id, page, create }) {
            // console.log(id, page, create);
            distributionData.value = null;
            distributionModalPage.value = page || 1;
            distributionUpdateId.value = id;
            if (create) {
                distributionUpdateId.value = null;
            }
            if (!id) {
                isCreateDistributionModal.value = true;
                return;
            }
            // fetch the id data
            distributionData.value = await fetchDistributionById(id);
            isCreateDistributionModal.value = true;
        }
        // end of distribution
        // function editDistribution(id) {
        //     editId.value = id;
        //     nextTick(() => {
        //         if (editModal.value) {
        //             const modalInstance = new Modal(editModal.value);
        //             modalInstance.show();
        //         }
        //     });
        // }
        const publisherColumns = ref([
            {
                field: "name",
                title: "Publisher Name",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Distributions",
                visible: true,
            },
        ]);
        const countryColumns = ref([
            {
                field: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                field: "actions",
                title: "Distributions",
                visible: true,
            },
        ]);
        const rows = ref([]);
        const nestedRow = ref([]);
        const dropdownOpen = ref(false);
        const countries = computed(() => store.getters.getCountries);
        const activeTab = ref("all");
        const getCountryCode = (countryName) => {
            const country = countries.value.find((c) => c.name === countryName);
            return country ? country.code.toLowerCase() : "default";
        };
        const fetchDataWithFilter = async () => {
            try {
                const { data } = await axios.get(`/api/distributions`);
                rows.value = data;
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        const toggleDistribution = (id) => {
            console.log(rows.value);
            console.log(id);
            let nestedRowData = rows.value.filter(
                (row) => row.country_id === id,
            );
            nestedRow.value = nestedRowData;
        };
        function deleteLeads(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const url = `/api/distributions/${id}`;
                    try {
                        // Make the POST request with axios
                        const response = await axios.delete(url, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        console.log(
                            response,
                            "dsdsdsddsdsdsd hihihihihihi dssd  dsd sd sd sd sds dsdsdsdsd",
                        );
                        if (response && response.data) {
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted",
                                text: response.data.message,
                            });
                            rows.value = rows.value.filter(
                                (row) => row.id !== id,
                            );
                        } else {
                            throw new Error("Unexpected response format");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });
                        console.error("Failed to delete:", error);
                    }
                }
            });
        }
        const toggleDropdown = () => {
            dropdownOpen.value = !dropdownOpen.value;
        };
        const toggleColumnVisibility = (field) => {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) column.visible = !column.visible;
        };
        onMounted(async () => {
            fetchDataWithFilter();
            // Initialize Select2 once the data is loaded and DOM is updated
            nextTick(() => {
                initializeSelect2();
            });
        });
        function initializeSelect2() {
            $(".select2").select2({
                width: "100%",
            });
        }
        return {
            loading,
            rows,
            nestedRow,
            allColumns,
            toggleColumnVisibility,
            dropdownOpen,
            toggleDropdown,
            activeTab,
            getCountryCode,
            apiUrl,
            publisherColumns,
            countryColumns,
            toggleDistribution,
            deleteLeads,
            openDistributionModal,
            distributionData,
            distributionModalPage,
            distributionUpdateId,
            // editDistribution,
            editId,
            // editModal,
            // createModal,
            isCreateDistributionModal,
        };
    },
};
</script>
<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
