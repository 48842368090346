<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div class="d-flex gap-2 mb-4">
            <!-- All Conversions list, ALL Leads -->
            <v-row class="pt-2">
                <v-col cols="6" md="3">
                    <v-select
                        v-model="selectedConversion"
                        :items="conversions"
                        item-title="name"
                        item-value="name"
                        return-object
                        label="Select Conversion"
                        variant="outlined"
                        density="compact"
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="6" md="3">
                    <v-select
                        v-model="selectedLead"
                        :items="leads"
                        item-title="name"
                        item-value="name"
                        return-object
                        label="Select Lead"
                        variant="outlined"
                        density="compact"
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-divider vertical v-if="$vuetify.display.mdAndUp"></v-divider>
                <v-col cols="6" md="2">
                    <v-autocomplete
                        v-model="searchId"
                        :items="searchItems"
                        item-value="id"
                        item-title="label"
                        variant="outlined"
                        hide-details
                        density="compact"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="3">
                    <v-textarea
                        v-model="searchValue"
                        placeholder="Search by selected field"
                        row-height="15"
                        rows="1"
                        variant="outlined"
                        density="compact"
                        hide-details
                        no-resize
                    ></v-textarea>
                </v-col>
                <v-col class="d-flex gap-2">
                    <v-spacer v-if="$vuetify.display.smAndDown"></v-spacer>
                    <!-- <v-btn
                        @click="searchLeads({ global: false })"
                        color="success"
                        variant="outlined"
                        size="small"
                        icon
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn> -->
                    <v-btn
                        @click="searchLeads({ global: true })"
                        icon
                        color="success"
                        variant="outlined"
                        size="small"
                    >
                        <v-icon>mdi-magnify-plus-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>

        <div class="d-flex justify-space-between">
            <div class="dropdown d-flex gap-2" ref="dropdown">
                <button
                    class="btn-shadow btn btn-dark btn-sm rounded dense mb-4"
                    @click="toggleDropdown"
                >
                    Columns
                </button>
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px; z-index: 11"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>

                <div class="d-flex gap-2" v-if="totalSelectedRows > 0">
                    {{
                        totalSelectedRows > 1
                            ? totalSelectedRows + " rows selected"
                            : totalSelectedRows + " row selected"
                    }}
                    <v-btn
                        variant="outlined"
                        size="small"
                        @click="clearSelection"
                    >
                        Clear selection
                    </v-btn>
                </div>
            </div>

            <div>
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn
                            append-icon="mdi-chevron-down"
                            prepend-icon="mdi-check"
                            color="indigo-darken-2"
                            v-bind="props"
                        >
                            Apply to selection
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="copyEmailToClipboard">
                            <v-list-item-title class="text-uppercase">
                                Copy emails to clipboard
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openOverrideStatusDialog">
                            <v-list-item-title class="text-uppercase">
                                Override status
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="markAsTestLeadsDialog = true">
                            <v-list-item-title class="text-uppercase">
                                Mark as test leads
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="openManageSelectionDialog">
                            <v-list-item-title class="text-uppercase">
                                Manage selection
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="openProgressLogsDialog">
                            <v-list-item-title class="text-uppercase">
                                progress logs
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>

        <!-- DataTable Component -->
        <div class="table-container">
            <Vue3Datatable
                height="100%"
                @rowSelect="countSelectedRows"
                sortColumn="created_at"
                sortDirection="desc"
                ref="datatable"
                :hasCheckbox="true"
                :columns="visibleColumns"
                :rows="rows"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
                :pageSize="params.pagesize"
                :pageSizeOptions="[10, 15, 30, 50, 100, 200, 500, 1000]"
                paginationInfo="{0} to {1} of {2}"
            >
                <!-- id -->
                <template #id="{ value }">
                    <div class="d-flex items-center gap-2">
                        <img
                            :src="`/assets/img/infobar.png`"
                            width="24"
                            class="max-w-none"
                            @click="leadHistory(value.id)"
                            alt="lead history"
                        />
                        <img
                            :src="`/assets/img/iconedit.png`"
                            width="24"
                            class="max-w-none"
                            @click="openUpdateLeadDialog(value.id)"
                            alt="lead history"
                        />
                        <img
                            v-if="value.is_injected === 1"
                            :src="`/assets/img/iconsyringe.png`"
                            alt="injected lead"
                        />

                        <div class="text-gray-600">
                            {{ value.id }}
                        </div>
                    </div>
                </template>

                <!-- Advertiser Name -->
                <template #advertiser_name="{ value }">
                    <a
                        :href="`/advertisers/${value.advertiser_id}/setting`"
                        class="text-decoration-underline"
                        @click.stop
                    >
                        {{ value.advertiser_name }}
                        ({{ value.advertiser_id }})
                    </a>
                </template>

                <!-- Brand Status -->
                <template #brand_status="{ value }">
                    <div class="d-flex items-center gap-2">
                        <img
                            :src="`/assets/img/infobar.png`"
                            width="24"
                            class="max-w-none"
                            @click="statusHistory(value.id)"
                            alt="brand status history"
                        />
                        <div class="text-gray-600">
                            {{ value.brand_status }}
                        </div>
                    </div>
                </template>

                <!-- country -->
                <template #country_name="{ value }">
                    <div class="d-flex items-center gap-2">
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            @error="handleFlagImageError"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>

                <!-- Affiliate Name -->
                <template #publisher_name="{ value }">
                    <a
                        :href="`/affiliates/${value.affiliate_id}/setting`"
                        class="text-decoration-underline"
                        @click.stop
                    >
                        {{ value.publisher_name }}
                        ({{ value.affiliate_id }})
                    </a>
                </template>

                <!-- Conversion Rate -->
                <template #cr="{ value }"> {{ value.cr }}% </template>

                <!-- Affiliate Conversion Rate -->
                <template #aff_cr="{ value }"> {{ value.aff_cr }}% </template>

                <template #distribution_name="{ value }">
                    {{ value.distribution_name }}
                    ({{ value.dis_id }})
                </template>

                <!-- Brand Status -->
                <template #status="{ value }">
                    <span
                        v-if="value.status === 'FTD'"
                        class="badge bg-success"
                    >
                        {{ value.status }}
                    </span>
                    <span
                        v-if="value.status === 'FTD REJECTED'"
                        class="badge badge-info"
                    >
                        {{ value.status }}
                    </span>
                    <span
                        v-if="value.status === 'Pending FTD'"
                        class="badge badge-warning"
                    >
                        {{ value.status }}
                    </span>
                    <span
                        v-if="value.status === 'Submitted'"
                        class="badge badge-secondary"
                    >
                        {{ value.status }}
                    </span>
                    <span
                        v-if="value.status === 'Rejected'"
                        class="badge badge-danger"
                    >
                        {{ value.status }}
                    </span>
                    <span
                        v-if="value.status === 'Accepted'"
                        class="badge badge-primary"
                    >
                        {{ value.status }}
                    </span>
                </template>

                <!-- Late FTD -->
                <template #late_ftd="{ value }">
                    <span
                        v-if="value.late_ftd === 'Yes'"
                        class="badge bg-success"
                    >
                        {{ value.late_ftd }}
                    </span>
                    <span v-else class="badge bg-danger">
                        {{ value.late_ftd }}
                    </span>
                </template>

                <!-- Actions -->
                <!-- <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <button
                            type="button"
                            class="btn btn-outline-success btn-sm"
                            @click="acceptLead(value.id)"
                        >
                            <i class="bi bi-check-circle"></i>
                            Edit
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-danger btn-sm"
                            @click="deleteLeads(value.id)"
                        >
                            <i class="bi bi-x-circle"></i>
                            Delete
                        </button>
                    </div>
                </template> -->
            </Vue3Datatable>
        </div>
    </div>

    <!-- vuetify dialog with table -->
    <v-dialog v-model="statusHistoryDialog" max-width="900px">
        <v-card-text>
            <!-- :headers="statusHistoryHeaders" -->
            <v-data-table :items="statusHistoryItems" class="elevation-1">
                <template
                    v-slot:headers="{
                        columns,
                        isSorted,
                        getSortIcon,
                        toggleSort,
                    }"
                >
                    <tr>
                        <template v-for="column in columns" :key="column.key">
                            <td>
                                <span
                                    class="mr-2 cursor-pointer"
                                    @click="() => toggleSort(column)"
                                    >{{
                                        column.title
                                            .split("_")
                                            .map(
                                                (word) =>
                                                    word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    word.slice(1),
                                            )
                                            .join(" ")
                                    }}</span
                                >
                                <template v-if="isSorted(column)">
                                    <v-icon
                                        :icon="getSortIcon(column)"
                                    ></v-icon>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            Status History
                            <b>
                                {{ statusHistoryEmail }} ({{ statusHistoryId }})
                            </b>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-dialog>
    <!-- vuetify dialog with table -->
    <v-dialog v-model="progressLogsDialog" max-width="900px">
        <v-card-text>
            <!-- :headers="statusHistoryHeaders" -->
            <v-data-table :items="progressLogsItems" class="elevation-1">
                <template
                    v-slot:headers="{
                        columns,
                        isSorted,
                        getSortIcon,
                        toggleSort,
                    }"
                >
                    <tr>
                        <template v-for="column in columns" :key="column.key">
                            <td>
                                <span
                                    class="mr-2 cursor-pointer"
                                    @click="() => toggleSort(column)"
                                    >{{
                                        column.title
                                            .split("_")
                                            .map(
                                                (word) =>
                                                    word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    word.slice(1),
                                            )
                                            .join(" ")
                                    }}</span
                                >
                                <template v-if="isSorted(column)">
                                    <v-icon
                                        :icon="getSortIcon(column)"
                                    ></v-icon>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Progress Logs</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-dialog>
    <!-- vuetify dialog with table -->
    <v-dialog v-model="leadHistoryDialog" max-width="900px">
        <v-card-text>
            <v-data-table :items="leadHistoryItems" class="elevation-1">
                <!-- modify header of all columns -->
                <template
                    v-slot:headers="{
                        columns,
                        isSorted,
                        getSortIcon,
                        toggleSort,
                    }"
                >
                    <tr>
                        <template v-for="column in columns" :key="column.key">
                            <td>
                                <span
                                    class="mr-2 cursor-pointer"
                                    @click="() => toggleSort(column)"
                                    >{{
                                        column.title
                                            .split("_")
                                            .map(
                                                (word) =>
                                                    word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    word.slice(1),
                                            )
                                            .join(" ")
                                    }}</span
                                >
                                <template v-if="isSorted(column)">
                                    <v-icon
                                        :icon="getSortIcon(column)"
                                    ></v-icon>
                                </template>
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>
                            Lead History
                            <b>
                                {{ leadHistoryEmail }} ({{ leadHistoryId }})
                            </b>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-toolbar>
                </template>
            </v-data-table>
        </v-card-text>
    </v-dialog>
    <!-- vuetify dialog with three text input field -->
    <v-dialog v-model="updateLeadDialog" persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Update Lead
                    <b> {{ updateLeadEmail }} ({{ updateLeadId }}) </b>
                </span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field
                        v-model="revenue"
                        label="Revenue"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="payout"
                        label="Payout"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="status_override"
                        label="Status Override"
                        required
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="updateLeadDialog = false"
                    >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="updateLead"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- override status dialog modal -->
    <v-dialog v-model="overrideStatusDialog" persistent max-width="900px">
        <v-card>
            <v-card-title>
                <span class="headline">Override Status</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-radio-group v-model="selectedOption" name="leadStatus">
                        <v-radio
                            label="Manual"
                            value="manual"
                            :input-value="selectedOption"
                        ></v-radio>
                        <v-radio
                            label="Choose from dropdown"
                            value="dropdown"
                            :input-value="selectedOption"
                        ></v-radio>
                    </v-radio-group>

                    <!-- Conditionally Display Text Field or Dropdown -->
                    <div v-if="selectedOption === 'manual'">
                        <v-text-field
                            placeholder="Enter manual input"
                            v-model="manualInput"
                            variant="outlined"
                            hide-details
                            clearable
                        ></v-text-field>
                    </div>

                    <div v-if="selectedOption === 'dropdown'">
                        <v-autocomplete
                            v-model="dropdownInput"
                            :items="dropdownStatuses"
                            placeholder="Select from dropdown"
                            item-title="brand_status"
                            item-value="brand_status"
                            variant="outlined"
                            hide-details
                            clearable
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip small>
                                    <span>{{ item.value }}</span>
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                    <p>Are you sure you want to reject the field?</p>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="overrideStatusDialog = false"
                    >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="overrideStatus"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- manage selection dialog modal -->
    <v-dialog v-model="manageSelectionDialog" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline">Send leads to a different brand</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-autocomplete
                        v-model="brandId"
                        :items="brands"
                        placeholder="Select a brand"
                        item-title="name"
                        item-value="id"
                        variant="outlined"
                        hide-details
                        clearable
                        :rules="[
                            (value) => !!value || 'Please select a valid brand',
                        ]"
                    >
                        <template v-slot:label>
                            <span>Brand</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <v-chip small>
                                <span>{{ item.title }}</span>
                            </v-chip>
                        </template>
                    </v-autocomplete>

                    <v-radio-group
                        class="ml-n2 mt-6"
                        v-model="locateLeadsBy"
                        label="Locate leads by"
                        inline
                        hide-details
                        color="primary"
                    >
                        <v-radio
                            label="System Default"
                            value="system"
                        ></v-radio>
                        <v-radio label="IP Address" value="ip"></v-radio>
                        <v-radio label="Phone" value="phone"></v-radio>
                    </v-radio-group>

                    <!-- simlate login checkbox -->
                    <v-checkbox
                        v-model="simulateLogin"
                        label="Simulate Login"
                        color="primary"
                        hide-details
                    ></v-checkbox>

                    <!-- delay checkbox -->
                    <v-checkbox
                        v-model="delay"
                        label="Delay"
                        color="primary"
                        hide-details
                    ></v-checkbox>

                    <!-- if delay is checked, show delay min and max delay input fields  -->
                    <div v-if="delay" class="d-flex gap-4">
                        <v-text-field
                            v-model="delayMin"
                            label="Min Delay"
                            variant="outlined"
                        ></v-text-field>
                        <v-text-field
                            v-model="delayMax"
                            label="Max Delay"
                            variant="outlined"
                        ></v-text-field>
                    </div>

                    <!-- seperator with heading Data override -->
                    <span class="headline">Data override</span>
                    <v-divider class="my-4"></v-divider>

                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Affiliate</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="affiliateOriginal"
                                color="primary"
                                label="Use Original"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-autocomplete
                                :disabled="affiliateOriginal"
                                v-model="affiliate"
                                :items="affiliates"
                                placeholder="Select an affiliate"
                                item-title="name"
                                item-value="id"
                                variant="outlined"
                                hide-details
                                clearable
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small>
                                        <span>{{ item.title }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <data> </data>
                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap mt-md-4 mt-2"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Funnel Slug</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="funnelOriginal"
                                color="primary"
                                label="Use Original"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-autocomplete
                                :disabled="funnelOriginal"
                                v-model="funnel"
                                :items="funnels"
                                placeholder="Select a funnel slug"
                                item-title="funnel"
                                item-value="funnel"
                                variant="outlined"
                                hide-details
                                clearable
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip small>
                                        <span>{{ item.title }}</span>
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <div
                        class="d-flex align-center gap-md-4 flex-wrap flex-md-nowrap mt-md-4 mt-2"
                    >
                        <div
                            class="d-flex justify-space-between align-center gap-4 flex-grow-1 w-75"
                        >
                            <div class="w-50">Affiliate ID Override</div>
                            <v-switch
                                class="w-50 flex-shrink-0"
                                v-model="affiliateIdDefault"
                                color="primary"
                                label="Use default"
                                hide-details
                            ></v-switch>
                        </div>
                        <div class="flex-grow-1 w-100">
                            <v-text-field
                                v-model="affiliateId"
                                :disabled="affiliateIdDefault"
                                placeholder="Enter affiliate ID override"
                                variant="outlined"
                                hide-details
                                clearable
                            ></v-text-field>
                        </div>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="manageSelectionDialog = false"
                    >Close</v-btn
                >
                <v-btn
                    color="blue darken-1"
                    :disabled="!brandId"
                    text
                    @click="manageSelection"
                    >Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- mark as test leads dialog modal -->
    <v-dialog v-model="markAsTestLeadsDialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Confirm Action</span>
            </v-card-title>
            <v-card-text>
                Are you sure you want to mark the selected leads as test leads?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="markAsTestLeadsDialog = false"
                    >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="markAsTestLeads"
                    >Yes, Confirm</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.table-container {
    max-height: 75vh;
    /* Set the height you need */
    overflow-y: auto;
    /* Enable vertical scrolling */
    overflow-x: auto;
    /* Enable horizontal scrolling if needed */
}

.status {
    text-align: center;
}

.vue3-datatable {
    width: 100%;
    /* Make sure the table takes the full width of the container */
}

.vue3-datatable thead th {
    position: sticky;
    top: 0;
    /* Stick the header to the top */
    background: white;
    /* Set a background color for better visibility */
    z-index: 1;
    /* Ensure the header is above the table body */
}
</style>

<script>
import {
    ref,
    computed,
    watch,
    onMounted,
    onBeforeUnmount,
    reactive,
} from "vue";

import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import {
    fetchLeadDetails,
    fetchStatusHistory,
    fetchLeadHistory,
    updateLeadDetails,
    requestStatusOverride,
    requestMarkAsTestLeads,
    fetchProgressLogs,
    fetchFilterData,
    requestManageSelection,
} from "./service";
import { useStore } from "vuex";

export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({}),
        },
        // availableStatuses: [],
    },

    setup(props) {
        const store = useStore();
        const appUrl = computed(() => process.env.VUE_APP_APP_URL);

        const params = reactive({
            current_page: 1,
            pagesize: 100,
        });
        const datatable = ref(null);
        const allColumns = ref([
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "advertiser_name",
                title: "Advertiser",
                visible: true,
                sortable: true,
            },
            {
                field: "brand_status",
                title: "Advertiser Status",
                visible: true,
                sortable: true,
            },
            {
                field: "publisher_name",
                title: "Publisher",
                visible: true,
                sortable: true,
            },
            {
                field: "country_name",
                title: "Country",
                visible: true,
                sortable: true,
            },
            {
                field: "dis_id",
                title: "Distribution Id",
                visible: false,
                sortable: true,
            },
            {
                field: "distribution_name",
                title: "Distribution",
                visible: true,
                sortable: true,
            },

            {
                field: "status",
                title: "Status",
                visible: true,
                sortable: true,
            },
            {
                field: "email",
                title: "Email",
                visible: true,
                sortable: true,
            },
            {
                field: "rejection_reason",
                title: "Rejection Reason",
                visible: true,
                sortable: true,
            },
            {
                field: "created_at",
                title: "Created",
                visible: true,
                sortable: true,
            },
            {
                field: "status_override",
                title: "Status Override",
                visible: true,
                sortable: true,
            },
            {
                field: "notes",
                title: "Notes",
                visible: false,
                sortable: true,
            },
            {
                field: "funnel",
                title: "Funnel",
                visible: true,
                sortable: true,
            },
            {
                field: "offer",
                title: "Offer",
                visible: true,
                sortable: true,
            },
            {
                field: "ftd_date",
                title: "FTD",
                visible: true,
                sortable: true,
            },
            {
                field: "broker_first_time_deposit",
                title: "BFTD",
                visible: true,
                sortable: true,
            },
            {
                field: "real_conversion",
                title: "Real Conversion",
                visible: false,
                sortable: true,
            },
            {
                field: "revenue",
                title: "Revenue",
                visible: true,
                sortable: true,
            },
            {
                field: "payout",
                title: "Payout",
                visible: true,
                sortable: true,
            },

            {
                field: "first_name",
                title: "First Name",
                visible: true,
                sortable: true,
            },
            {
                field: "last_name",
                title: "Last Name",
                visible: true,
                sortable: true,
            },
            {
                field: "phone",
                title: "Phone",
                visible: true,
                sortable: true,
            },
            {
                field: "ip",
                title: "IP",
                visible: true,
                sortable: true,
            },
            // {
            //     field: "action",
            //     title: "Action",
            //     visible: false,
            //     sortable: true,
            // },
            {
                field: "custom_param_1",
                title: "Custom Param 1",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_2",
                title: "Custom Param 2",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_3",
                title: "Custom Param 3",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_4",
                title: "Custom Param 4",
                visible: false,
                sortable: true,
            },
            {
                field: "custom_param_5",
                title: "Custom Param 5",
                visible: false,
                sortable: true,
            },
            {
                field: "publisher_status",
                title: "Publisher Sale Status",
                visible: true,
                sortable: true,
            },
            {
                field: "autologged_at",
                title: "Autologged At",
                visible: true,
                sortable: true,
            },
            {
                field: "autologin_url",
                title: "Autologin URL",
                visible: true,
                sortable: true,
            },
            {
                field: "redirect_url",
                title: "Redirect URL",
                visible: true,
                sortable: true,
            },
            {
                field: "city",
                title: "City",
                visible: true,
                sortable: true,
            },
        ]);

        const rows = ref([]);
        const totalSelectedRows = ref(0);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const editId = ref(null);
        const countries = computed(() => store.getters.getCountries);
        const conversions = ref([
            {
                name: "All",
                value: null,
            },
            {
                name: "Pending",
                value: [6],
            },
            {
                name: "Converted",
                value: [5, 6, 7],
            },
            {
                name: "Non Converted",
                value: [2, 3],
            },
        ]);
        const leads = ref([
            {
                name: "All",
                value: null,
            },
            {
                name: "Successful Only",
                value: [2, 5, 6, 7],
            },
            {
                name: "Failed Only",
                value: [3],
            },
        ]);
        const selectedConversion = ref({
            name: "All",
            value: null,
        });
        const selectedLead = ref({
            name: "All",
            value: null,
        });

        const searchItems = ref([
            {
                id: "email",
                label: "Email",
            },
            {
                id: "first_name",
                label: "First Name",
            },
            {
                id: "last_name",
                label: "Last Name",
            },
            {
                id: "funnel",
                label: "Funnel",
            },

            {
                id: "custom_param_1",
                label: "Custom Param 1",
            },
            {
                id: "custom_param_2",
                label: "Custom Param 2",
            },
            {
                id: "custom_param_3",
                label: "Custom Param 3",
            },
            {
                id: "custom_param_4",
                label: "Custom Param 4",
            },
            {
                id: "custom_param_5",
                label: "Custom Param 5",
            },
            {
                id: "id",
                label: "Lead Id",
            },
            {
                id: "hit_id",
                label: "Hit Id",
            },
            {
                id: "ip",
                label: "IP Address",
            },
        ]);

        const searchId = ref("email");
        const searchValue = ref(null);

        // status history dialog modal
        const statusHistoryDialog = ref(false);
        const statusHistoryItems = ref([]);
        const statusHistoryEmail = ref(null);
        const statusHistoryId = ref(null);
        // const statusHistoryHeaders = ref([
        //     { title: "Date", key: "created_at" },
        //     { title: "Advertiser Status", key: "advertiser_status" },
        //     { title: "Publisher Status", key: "publisher_status" },
        // ]);

        // lead history dialog modal
        const leadHistoryDialog = ref(false);
        const leadHistoryItems = ref([]);
        const leadHistoryEmail = ref(null);
        const leadHistoryId = ref(null);

        // progress logs dialog modal
        const progressLogsDialog = ref(false);
        const progressLogsItems = ref([]);

        // update lead dialog modal
        const updateLeadDialog = ref(false);
        const updateLeadId = ref(null);
        const updateLeadEmail = ref(null);
        const revenue = ref(0);
        const payout = ref(null);
        const status_override = ref("");

        // override status dialog modal
        const overrideStatusDialog = ref(false);
        const selectedOption = ref("manual"); // This will be either 'manual' or 'dropdown'
        const manualInput = ref(null); // To hold the value of the manual input
        const dropdownStatuses = ref([]); // To hold the value of the dropdown statuses
        const dropdownInput = ref(null); // To hold the value of the dropdown selection

        // manage selection dialog modal
        const manageSelectionDialog = ref(false);
        const brandId = ref(null);
        const brands = ref([]);
        const locateLeadsBy = ref("system");
        const simulateLogin = ref(false);
        const delay = ref(false);
        const delayMin = ref(null);
        const delayMax = ref(null);
        const affiliateOriginal = ref(true);
        const affiliate = ref(null);
        const affiliates = ref([]);
        const funnelOriginal = ref(true);
        const funnel = ref(null);
        const funnels = ref([]);
        const affiliateIdDefault = ref(true);
        const affiliateId = ref(null);

        // mark as test leads dialog modal
        const markAsTestLeadsDialog = ref(false);

        const manageSelection = async () => {
            const selectedRows = datatable.value.getSelectedRows();
            if (selectedRows.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No row selected",
                });
                manageSelectionDialog.value = false;
                return;
            }
            try {
                const lead_ids = selectedRows.map((row) => row.id);
                const payload = {
                    lead_ids: lead_ids,
                    advertiser_id: brandId.value,
                    locate_leads: locateLeadsBy.value,
                    simulate: simulateLogin.value,
                    min_delay: delay.value ? delayMin.value : null,
                    max_delay: delay.value ? delayMax.value : null,
                    publisher_id: !affiliateOriginal.value
                        ? affiliate.value
                        : null,
                    funnel: !funnelOriginal.value ? funnel.value : null,
                    publisher_id_override: !affiliateIdDefault.value
                        ? affiliateId.value
                        : null,
                };

                const data = await requestManageSelection(payload);
                if (!data) {
                    throw new Error("Failed to update data");
                }
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                });
                console.log("data", data);
            } catch (error) {
                console.log("error on manage selection", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to update. Please try again.",
                });
            } finally {
                manageSelectionDialog.value = false;
            }
        };

        const openManageSelectionDialog = async () => {
            manageSelectionDialog.value = true;
            try {
                const data = await fetchFilterData();
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                brands.value = data.advertisers;
                affiliates.value = data.publishers;
                funnels.value = data.funnel;
            } catch (error) {
                console.log("error on opening override status dialog", error);
            }
        };

        const openOverrideStatusDialog = async () => {
            overrideStatusDialog.value = true;
            try {
                const data = await fetchFilterData();
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                console.log("data", data);
                dropdownStatuses.value = data.statuses.filter(
                    (val) => !!val.brand_status,
                );
            } catch (error) {
                console.log("error on opening override status dialog", error);
            }
        };
        const overrideStatus = async () => {
            try {
                console.log("override status", dropdownInput.value);
                const selectedRows = datatable.value.getSelectedRows();
                const lead_ids = selectedRows.map((row) => row.id);
                if (!lead_ids.length) {
                    throw new Error("NO_ROW_SELECTED");
                }
                const payload = {
                    lead_ids: lead_ids,
                    status_override:
                        selectedOption.value.toLowerCase() === "manual"
                            ? manualInput.value
                            : dropdownInput.value,
                };
                const data = await requestStatusOverride(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                Swal.fire({
                    icon: "success",
                    title: "Status Override Updated",
                });
            } catch (error) {
                if (error.message === "NO_ROW_SELECTED") {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "No row selected",
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Failed to update the lead. Please try again.",
                    });
                }
            } finally {
                overrideStatusDialog.value = false;
            }
        };
        const copyEmailToClipboard = () => {
            console.log(
                "copy email to clipboard",
                datatable.value.getSelectedRows(),
            );
            const selectedRows = datatable.value.getSelectedRows();
            if (selectedRows.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No row selected",
                });
                return;
            }
            const emails = selectedRows.map((row) => row.email);
            console.log("emails", emails);
            navigator.clipboard.writeText(emails.join(", "));
            Swal.fire({
                icon: "success",
                title: "Emails copied to clipboard",
            });
        };
        const markAsTestLeads = async () => {
            const selectedRows = datatable.value.getSelectedRows();
            if (selectedRows.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "No row selected",
                });
                markAsTestLeadsDialog.value = false;
                return;
            }
            try {
                const lead_ids = selectedRows.map((row) => row.id);
                const payload = {
                    lead_ids: lead_ids,
                };
                const data = await requestMarkAsTestLeads(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                Swal.fire({
                    icon: "success",
                    title: "Leads Marked as Test",
                });
            } catch (error) {
                console.log("error on marking as test leads", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to update the lead. Please try again.",
                });
            } finally {
                markAsTestLeadsDialog.value = false;
            }
        };
        const getCountryCode = (countryName) => {
            const country = countries.value.find((c) => c.name === countryName);
            return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
        };
        const countSelectedRows = (rows) => {
            totalSelectedRows.value = rows.length;
        };
        const clearSelection = () => {
            datatable.value.clearSelectedRows();
        };

        const statusHistory = async (id) => {
            const selectedRowData = rows.value.find((row) => row.id === id);
            statusHistoryEmail.value = selectedRowData.email;
            statusHistoryId.value = id;
            try {
                const payload = {
                    tz_offset: "+03:00",
                    lead_id: id,
                };
                const data = await fetchStatusHistory(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                console.log("data", data);
                statusHistoryItems.value = data;
                statusHistoryDialog.value = true;
            } catch (error) {
                console.log("error on fetching status history", error);
            }
        };

        const openProgressLogsDialog = async () => {
            progressLogsDialog.value = true;
            try {
                const data = await fetchProgressLogs();
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                progressLogsItems.value = data;
            } catch (error) {
                console.log("error on fetching progress logs", error);
            }
        };

        const leadHistory = async (id) => {
            const selectedRowData = rows.value.find((row) => row.id === id);
            leadHistoryEmail.value = selectedRowData.email;
            leadHistoryId.value = id;
            try {
                const payload = {
                    tz_offset: "+03:00",
                    lead_id: id,
                };
                const data = await fetchLeadHistory(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                console.log("data", data);
                leadHistoryItems.value = data;
                leadHistoryDialog.value = true;
            } catch (error) {
                console.log("error on fetching lead history", error);
            }
        };

        const openUpdateLeadDialog = (id) => {
            console.log("Lead: " + id);
            editId.value = id;
            // autofill form
            const selectedRowData = rows.value.find((row) => row.id === id);
            updateLeadId.value = id;
            updateLeadEmail.value = selectedRowData.email;
            console.log("selected Row data", selectedRowData);
            revenue.value = selectedRowData.revenue;
            payout.value = selectedRowData.payout;
            status_override.value = selectedRowData.status_override;
            // show modal
            updateLeadDialog.value = true;
        };

        const updateLead = async () => {
            try {
                console.log(editId.value);
                const payload = {
                    lead_id: editId.value,
                    revenue: revenue.value,
                    payout: payout.value,
                    status_override: status_override.value,
                };
                const data = await updateLeadDetails(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                Swal.fire({
                    icon: "success",
                    title: "Lead Updated",
                });
            } catch (error) {
                console.log("failed to update lead details", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to update the lead. Please try again.",
                });
            } finally {
                updateLeadDialog.value = false;
            }
        };

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            document.addEventListener("click", handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        const searchLeads = async ({ global }) => {
            const filterData = props.filterData;
            try {
                const payload = {
                    tz_offset: "+03:00",
                    advertiser_id: filterData?.advertiser_id || null, // Adjusted to match the parameters
                    country_id: filterData?.country_id || null,
                    publisher_id: filterData?.publisher_id || null,
                    brand_status: filterData?.brand_status || null,
                    start_date: filterData?.start_date || null, // Send null if date is not provided
                    end_date: filterData?.end_date || null,
                    custom_param_1: filterData?.custom_param_1 || null,
                    custom_param_2: filterData?.custom_param_2 || null,
                    custom_param_3: filterData?.custom_param_3 || null,
                    custom_param_4: filterData?.custom_param_4 || null,
                    custom_param_5: filterData?.custom_param_5 || null,
                    publisher_status: filterData?.publisher_status || null,
                    offer: filterData?.offer || null,
                    email: null,
                    search_txt: searchValue.value ? [searchValue.value] : null,
                    search_in: searchId.value,
                    is_search_global: global,
                };
                // console.log(
                //     "logging",
                //     ...(selectedConversion.value?.value || []),
                // );
                // add selectedLead, selectedConversion
                payload["lead_status_id"] = [
                    ...new Set([
                        ...(selectedLead.value?.value || []),
                        ...(selectedConversion.value?.value || []),
                    ]),
                ];
                if (!payload["lead_status_id"]?.length) {
                    payload["lead_status_id"] = null;
                }
                const data = await fetchLeadDetails(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                console.log("Updated rows", data);
                rows.value = data;
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        const fetchRowsByFilter = async (filterData) => {
            console.log("Filter data:", filterData);
            try {
                const payload = {
                    tz_offset: "+03:00",
                    advertiser_id: filterData?.advertiser_id || null, // Adjusted to match the parameters
                    country_id: filterData?.country_id || null,
                    publisher_id: filterData?.publisher_id || null,
                    brand_status: filterData?.brand_status || null,
                    start_date: filterData?.start_date || null, // Send null if date is not provided
                    end_date: filterData?.end_date || null,
                    custom_param_1: filterData?.custom_param_1 || null,
                    custom_param_2: filterData?.custom_param_2 || null,
                    custom_param_3: filterData?.custom_param_3 || null,
                    custom_param_4: filterData?.custom_param_4 || null,
                    custom_param_5: filterData?.custom_param_5 || null,
                    publisher_status: filterData?.publisher_status || null,
                    offer: filterData?.offer || null,
                    email: null,
                    search_txt: searchValue.value ? [searchValue.value] : null,
                    search_in: searchId.value,
                    is_search_global: false,
                };
                // console.log(
                //     "logging",
                //     ...(selectedConversion.value?.value || []),
                // );
                // add selectedLead, selectedConversion
                payload["lead_status_id"] = [
                    ...new Set([
                        ...(selectedLead.value?.value || []),
                        ...(selectedConversion.value?.value || []),
                    ]),
                ];
                if (!payload["lead_status_id"]?.length) {
                    payload["lead_status_id"] = null;
                }
                const data = await fetchLeadDetails(payload);
                if (!data) {
                    throw new Error("Failed to fetch data");
                }
                console.log("Updated rows", data);
                rows.value = data;
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };

        watch(
            () => props.filterData,
            (newValue) => {
                console.log("New value:", newValue);
                fetchRowsByFilter(newValue);
            },
            {
                deep: true,
            },
        );

        return {
            params,
            rows,
            allColumns,
            visibleColumns,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            appUrl,
            getCountryCode,
            statusHistoryDialog,
            statusHistoryItems,
            // statusHistoryHeaders,
            statusHistory,
            statusHistoryEmail,
            statusHistoryId,
            leadHistory,
            leadHistoryItems,
            leadHistoryEmail,
            leadHistoryId,
            leadHistoryDialog,
            openUpdateLeadDialog,
            updateLeadDialog,
            updateLeadId,
            updateLeadEmail,
            revenue,
            payout,
            status_override,
            updateLead,
            conversions,
            leads,
            selectedConversion,
            selectedLead,
            searchItems,
            searchId,
            searchValue,
            searchLeads,
            copyEmailToClipboard,
            datatable,
            overrideStatusDialog,
            selectedOption,
            manualInput,
            dropdownStatuses,
            dropdownInput,
            overrideStatus,
            markAsTestLeads,
            markAsTestLeadsDialog,
            progressLogsDialog,
            progressLogsItems,
            openProgressLogsDialog,
            openOverrideStatusDialog,
            countSelectedRows,
            totalSelectedRows,
            clearSelection,
            manageSelectionDialog,
            brandId,
            brands,
            locateLeadsBy,
            simulateLogin,
            delay,
            delayMin,
            delayMax,
            affiliateOriginal,
            affiliate,
            affiliates,
            funnelOriginal,
            funnel,
            funnels,
            affiliateIdDefault,
            affiliateId,
            openManageSelectionDialog,
            manageSelection,
        };
    },
};
</script>

<style scoped>
/* Change the header background color */
.bh-datatable .bh-table thead th {
    background-color: black;
    /* Change to your desired color */
    color: #fff;
    /* Change to text color */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown-button:hover {
    background-color: #45a049;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 10px;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
</style>
