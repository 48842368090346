<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-form ref="capRef">
                <v-card>
                    <v-card-title>
                        <span class="headline" v-if="editId === null"
                            >Add New Cap</span
                        >
                        <span class="headline" v-else>Edit Cap</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        label="Type"
                                        v-model="capType"
                                        :items="[
                                            {
                                                text: 'Lead',
                                                value: 'lead',
                                            },
                                            {
                                                text: 'FTD',
                                                value: 'ftd',
                                            },
                                        ]"
                                        item-title="text"
                                        item-value="value"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid type',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <!-- :items="['Daily', 'Interval']" -->
                                    <v-select
                                        item-title="text"
                                        item-value="value"
                                        :items="[
                                            {
                                                text: 'Daily',
                                                value: 'daily',
                                            },
                                            {
                                                text: 'Interval',
                                                value: 'interval',
                                            },
                                        ]"
                                        label="Interval"
                                        v-model="interval"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please select a valid interval',
                                        ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="resetAt"
                                        label="Enter time (HH:MM)"
                                        placeholder="HH:MM"
                                        variant="outlined"
                                        :rules="[
                                            (value) =>
                                                validateTime(value) ||
                                                'Please enter a valid time',
                                        ]"
                                        prepend-inner-icon="mdi-clock-outline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-autocomplete
                                        label="Timezone"
                                        v-model="timezone"
                                        :items="timezones"
                                        item-title="name"
                                        item-value="id"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Timezone is required',
                                        ]"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Cap"
                                        v-model="cap"
                                        variant="outlined"
                                        type="number"
                                        :rules="[
                                            (value) =>
                                                !!value ||
                                                'Please enter a valid cap',
                                            (value) =>
                                                value >= 1 ||
                                                'Cap should be greater than 0',
                                        ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        label="Countries"
                                        v-model="selectedCountry"
                                        variant="outlined"
                                        :items="countries"
                                        item-title="name"
                                        item-value="id"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Please select at least one country',
                                            (v) =>
                                                v.length > 0 ||
                                                'Please select at least one country',
                                        ]"
                                        multiple
                                        clearable
                                    >
                                        <!-- :rules="[
                                        (value) =>
                                            value.length > 0 ||
                                            'Please select a valid country',
                                    ]" -->
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <!-- {{ item.raw }} -->
                                            <v-chip
                                                small
                                                @click="
                                                    selectedCountry.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                                class="p-2"
                                            >
                                                <img
                                                    :src="item.raw.country_icon"
                                                    :alt="item.raw.code"
                                                    class="img-flags"
                                                />
                                                &nbsp;
                                                {{ item.raw.name }}
                                            </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="selectedPublishers"
                                        :items="publishers"
                                        label="Publishers"
                                        item-title="name"
                                        item-value="id"
                                        :return-object="false"
                                        multiple
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Please select at least one publisher',
                                            (v) =>
                                                v.length > 0 ||
                                                'Please select at least one publisher',
                                        ]"
                                        clearable
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedPublishers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-combobox
                                        v-model="selectedAdvertisers"
                                        :items="advertisers"
                                        label="Advertisers"
                                        item-title="name"
                                        item-value="id"
                                        :return-object="false"
                                        multiple
                                        variant="outlined"
                                        clearable
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Please select at least one status',
                                            (v) =>
                                                v.length > 0 ||
                                                'Please select at least one status',
                                        ]"
                                    >
                                        <template
                                            v-slot:selection="{ item, index }"
                                        >
                                            <v-chip
                                                small
                                                @click="
                                                    selectedAdvertisers.splice(
                                                        index,
                                                        1,
                                                    )
                                                "
                                                close
                                            >
                                                {{ item.title }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="blue darken-1" @click="saveCaps">
                            {{ editId === null ? "Create" : "Save" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- Dropdown for column visibility -->
        <!-- <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="dropdown" ref="dropdown">
                <button
                    class="btn-shadow btn btn-dark btn-sm dense"
                    @click="toggleDropdown"
                >
                    Columns
                </button>
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <a @click="createUser()" class="btn btn-primary float-end mb-4">
                <span>+</span>
            </a>
        </div> -->
        <div class="statusDiv mb-4">
            <v-btn @click="addRecord">Create</v-btn>
        </div>
        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <Vue3Datatable
                height="100%"
                :columns="columns"
                :rows="capData"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <button
                            type="button"
                            class="btn btn-outline-success"
                            @click="editCapsRow(value.id)"
                        >
                            <i class="bi bi-check-circle"></i>
                            Edit
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-danger"
                            @click="deleteCapsRow(value.id)"
                        >
                            <i class="bi bi-x-circle"></i>
                            Delete
                        </button>
                    </div>
                </template>
            </Vue3Datatable>
        </div>
        <!-- Edit Modal -->
        <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent :editId="editId" />
        </div>
        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="creatModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
    ref,
    computed,
    watch,
    defineProps,
    defineEmits,
    onMounted,
    reactive,
    onBeforeUnmount,
    nextTick,
} from "vue";
// /src/components/caps/service.js
import {
    fetchFilterData,
    fetchCaps,
    createCaps,
    updateCaps,
    deleteCaps,
    fetchTimezones,
} from "../../../components/caps/service";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import EditComponent from "./EditComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import { useStore } from "vuex";
const store = useStore();
const apiUrl = computed(() => process.env.VUE_APP_API_URL);
const allColumns = ref([
    {
        field: "id",
        title: "ID",
        visible: true,
        sortable: true,
    },
    {
        field: "name",
        title: "Name",
        visible: true,
        sortable: true,
    },
    {
        field: "email",
        title: "Email",
        visible: true,
        sortable: true,
    },
    {
        field: "level",
        title: "Level",
        visible: true,
        sortable: true,
    },
    {
        field: "actions",
        title: "Actions",
        visible: true,
    },
]);
const loading = ref(false);
const Props = defineProps({
    cap: {
        type: [Object, Array],
        default: () => [],
    },
});
const dialog = ref(false);
const capData = ref([]);
watch(
    () => Props.cap,
    (newVal) => {
        capData.value = newVal;
    },
    { immediate: true },
);
const Emit = defineEmits(["reloadApi"]);
const columns = [
    { field: "id", title: "ID", width: "90px", filter: true },
    { field: "cap", title: "cap" },
    { field: "filled", title: "Filled" },
    { field: "type", title: "Type" },
    { field: "interval", title: "Interval" },
    { field: "reset_time", title: "Reset Time" },
    {
        field: "actions",
        title: "Actions",
        width: "150px",
        filter: false,
        sortable: false,
    },
];
// const editRecord = (record) => {
//     console.log("Edit Record:", record);
//     // form.value.country_id = record.country.id;
//     // form.value.amount = record.amount;
//     // form.value.revenue_id = record.id;
//     dialog.value = true;
// };
const addRecord = async () => {
    // await revenueFormRef.value.reset();
    dialog.value = true;
};
const submitForm = async () => {
    // console.log("Form Data:", form.value);
    dialog.value = false;
};
const params = reactive({
    current_page: 1,
    pagesize: 100,
});
// Interval	Type Filled	Countries	Advertiser	Publisher	Reset At	Timezone	Action
const rows = ref([]);
const dropdownOpen = ref(false);
const dropdown = ref(null);
const interval = ref("");
const editId = ref(null);
const capType = ref("");
const resetAt = ref(null);
const resetAtMenu = ref(false);
const timezone = ref("");
const timezones = ref([]);
const cap = ref(0);
const countries = computed(() => store.getters.getCountries);
const selectedCountry = ref([]);
const publishers = ref([]);
const selectedPublishers = ref([]);
const advertisers = ref([]);
const selectedAdvertisers = ref([]);
const progress = ref(0);
function validateTime(time) {
    if (!time) {
        return false;
    }
    const timeParts = time.split(":");
    if (timeParts.length !== 2) {
        return false;
    }
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    if (timeParts[0].length !== 2 || timeParts[1].length !== 2) {
        return false;
    }
    if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
        return true;
    }
    return false;
}
function calculateProgress(filled, cap) {
    if (cap === 0) {
        return 100;
    }
    return Math.round((filled / cap) * 100);
}
function getCountry(id) {
    return countries.value.find((obj) => obj?.id === id);
}
function toggleDropdown() {
    dropdownOpen.value = !dropdownOpen.value;
}
// const editModal = ref(null);
// const createModal = ref(null);
async function editCapsRow(id) {
    console.log("Accept Lead: " + id);
    // enable editId
    editId.value = id;
    // autofill form
    const selectedRowData = capData.value?.find((row) => row.id === id);
    console.log(
        "🚀 ~ file: DataTableComponent.vue:500 ~ editCapsRow ~ selectedRowData:",
        selectedRowData.timezone_id,
    );
    selectedPublishers.value = selectedRowData.affiliate_id;
    selectedAdvertisers.value = selectedRowData.advertiser_id;
    timezone.value = selectedRowData.timezone_id;
    selectedCountry.value = selectedRowData.country_ids;
    interval.value = selectedRowData.interval;
    capType.value = selectedRowData.type;
    // type.value = selectedRowData?.type;
    resetAt.value =
        selectedRowData.reset_time.split(":")[0] +
        ":" +
        selectedRowData.reset_time.split(":")[1];
    cap.value = selectedRowData.id;
    dialog.value = true;
}
async function createRow() {
    // fetch fill the autocomplete
    editId.value = null;
    selectedPublishers.value = [];
    selectedAdvertisers.value = [];
    selectedCountry.value = [];
    interval.value = "";
    capType.value = "";
    resetAt.value = null;
    timezone.value = "";
    cap.value = 0;
    // show modal
    dialog.value = true;
}
const capRef = ref(null);
async function saveCaps() {
    const { valid } = await capRef.value.validate();
    if (!valid) {
        return;
    }
    const data = {
        advertiser_ids: selectedAdvertisers.value,
        type: capType.value,
        interval: interval.value,
        cap: String(cap.value),
        timezone_id: timezone.value,
        country_ids: selectedCountry.value,
        affiliate_ids: selectedPublishers.value,
        reset_time: resetAt.value || "00:00",
    };
    try {
        if (editId.value !== null) {
            // edit cap
            data.cap_id = editId.value;
            const response = await updateCaps(data);
            if (!response) {
                throw new Error("Failed to update");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully updated",
                text: "Cap updated successfully",
            });
            // console.log("Edit Cap", response);
        } else {
            console.log("Create Cap payload", data);
            const response = await createCaps(data);
            if (!response) {
                throw new Error("Failed to create");
            }
            Emit("reloadApi");
            Swal.fire({
                icon: "success",
                title: "Successfully created",
                text: "Cap created successfully",
            });
            // console.log("Create Cap", response);
        }
    } catch (error) {
        console.error("Failed to save Cap:", error);
        Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to save Cap. Please try again.",
        });
    } finally {
        dialog.value = false;
    }
}
function deleteCapsRow(id) {
    console.log("Reject Lead: " + id);
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Make the POST request with axios
                const data = await deleteCaps(id);
                if (!data) {
                    throw new Error("Failed to delete");
                }
                Swal.fire({
                    icon: "success",
                    title: "Successfully deleted",
                    text: data.message,
                });
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
function toggleColumnVisibility(field) {
    const column = allColumns.value.find((col) => col.field === field);
    if (column) {
        column.visible = !column.visible;
    }
}
const visibleColumns = computed(() => {
    return allColumns.value.filter((col) => col.visible);
});
function handleClickOutside(event) {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        dropdownOpen.value = false;
    }
}
onMounted(async () => {
    // rows.value = (await fetchCaps()) || [];
    timezones.value = (await fetchTimezones()) || [];
    publishers.value = (await fetchFilterData()).publishers || [];
    advertisers.value = (await fetchFilterData()).advertisers || [];
    document.addEventListener("click", handleClickOutside);
});
onBeforeUnmount(() => {
    document.removeEventListener("click", handleClickOutside);
});
// const rows = ref([]);
// const dropdownOpen = ref(false);
// const dropdown = ref(null);
// const countries = computed(() => store.getters.getCountries);
// const editId = ref(null);
// const getCountryCode = (countryName) => {
//     const country = countries.value.find((c) => c.name === countryName);
//     return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
// };
// function toggleDropdown() {
//     dropdownOpen.value = !dropdownOpen.value;
// }
// const editModal = ref(null);
// const createModal = ref(null);
// function createUser() {
//     nextTick(() => {
//         if (createModal.value) {
//             const modalInstance = new Modal(createModal.value);
//             modalInstance.show();
//         }
//     });
// }
// function editLead(id) {
//     console.log("Accept Lead: " + id);
//     editId.value = id;
//     nextTick(() => {
//         if (editModal.value) {
//             const modalInstance = new Modal(editModal.value);
//             modalInstance.show();
//         }
//     });
// }
// function deleteLeads(id) {
//     console.log("Reject Lead: " + id);
//     Swal.fire({
//         title: "Are you sure?",
//         text: `You are sure about to delete`,
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!",
//         cancelButtonText: "No, cancel",
//     }).then(async (result) => {
//         if (result.isConfirmed) {
//             const url = `/api/users/${id}/delete`;
//             try {
//                 // Make the POST request with axios
//                 const response = await axios.delete(url, {
//                     headers: {
//                         "Content-Type": "application/json",
//                     },
//                 });
//                 // Check if the response has a message
//                 if (response.data && response.data.message) {
//                     // Display success alert if the response contains the message
//                     Swal.fire({
//                         icon: "success",
//                         title: "Successfully deleted",
//                         text: response.data.message,
//                     });
//                 } else {
//                     throw new Error("Unexpected response format");
//                 }
//             } catch (error) {
//                 // Handle error and display an error alert
//                 Swal.fire({
//                     icon: "error",
//                     title: "Error",
//                     text: "Failed to delete. Please try again.",
//                 });
//                 console.error("Failed to delete:", error);
//             }
//         }
//     });
// }
// function toggleColumnVisibility(field) {
//     const column = allColumns.value.find((col) => col.field === field);
//     if (column) {
//         column.visible = !column.visible;
//     }
// }
// const visibleColumns = computed(() => {
//     return allColumns.value.filter((col) => col.visible);
// });
// // const filteredRows = computed(() => {
// //     return rows.value.filter((row) => {
// //         return Object.keys(props.filterData.filters || {}).every((key) => {
// //             return (
// //                 !props.filterData.filters[key] ||
// //                 row[key] === props.filterData.filters[key]
// //             );
// //         });
// //     });
// // });
// async function fetchDataWithFilter() {
//     const url = `/api/users`;
//     console.log("Fetching data from URL:", url);
//     try {
//         const { data } = await axios.get(url);
//         rows.value = data; // Assuming rows is a reactive variable in Vue 3
//         console.log("Received data:", data);
//     } catch (error) {
//         console.error("Failed to fetch data:", error);
//     }
// }
// function handleClickOutside(event) {
//     if (dropdown.value && !dropdown.value.contains(event.target)) {
//         dropdownOpen.value = false;
//     }
// }
// onMounted(async () => {
//     fetchDataWithFilter();
//     document.addEventListener("click", handleClickOutside);
// });
// onBeforeUnmount(() => {
//     document.removeEventListener("click", handleClickOutside);
// });
</script>
<style scoped>
/* .table-container {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    } */
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}
.dropdown-content label {
    display: block;
    padding: 5px 0;
}
.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
