import axios from "axios";
import router from "./router";
import { useStore } from "vuex";
const store = useStore();
// Set the default baseURL for all Axios requests
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://localhost:8080";

// // Add a request interceptor
axios.interceptors.request.use(
    (config) => {
        // Get the user data from local storage
        const userData = localStorage.getItem("user_data");
        if (userData) {
            const user = JSON.parse(userData);
            // Set the token & timezone offset in the Authorization header
            config.headers["Authorization"] = `Bearer ${user.token}`;
            config.headers["timezone_offset"] = `${user.timezone}`;
        }
        return config;
    },
    (error) => {
        console.log("error: ", error);
        // Handle the error
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("user_data");
            store.commit("setUser", null);
            router.push("/login");
        }
        return Promise.reject(error.response);
    },
);

// Export the default axios instance for use in your application
export default axios;
