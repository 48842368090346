<template>
    <img
        v-if="countryCode"
        :src="`/assets/img/flags/${countryCode}.png`"
        width="24"
        class="max-w-none img-flags"
        alt="country flag"
        error="/assets/img/flags/default.png"
    />
</template>

<script>
import countries from "./countries.json";
export default {
    props: {
        country_name: {
            type: String,
            required: true,
        },
    },
    computed: {
        countryList() {
            return countries;
        },
        countryCode() {
            return this.countryList[this.country_name] || "";
        },
    },
};
</script>
