<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="700">
            <v-form ref="openingHourRef">
                <!-- {{timeSlots}} -->
                <v-card>
                    <v-card-title> New Timeslot </v-card-title>
                    <v-card-text>
                        <v-combobox
                            label="Country Name"
                            variant="outlined"
                            v-model="country"
                            :items="countries"
                            multiple
                            item-value="id"
                            :return-object="false"
                            item-title="name"
                            :rules="[(v) => !!v || 'Country is required']"
                        >
                        </v-combobox>
                        <v-autocomplete
                            v-model="timeZone"
                            :items="timeZones"
                            label="Select a timezone"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                        >
                        </v-autocomplete>
                        <!-- <v-checkbox
                            class="ml-n2"
                            v-model="traffic"
                            label="Traffic (Reject leads outside opening hours)"
                            color="primary"
                            hide-details
                            disabled
                        ></v-checkbox> -->
                        <!-- <v-text-field
                            v-model="timeSlots[timeSlotIndex].name"
                            label="Name"
                            variant="outlined"
                            hide-details
                            density="compact"
                            hint="Name that will be used to reference this timeslot"
                        ></v-text-field> -->
                        <div class="mt-4">
                            <div class="text-subtitle-1">Opening Hours</div>
                            <!-- It going to contain 7 rows for each day of the week, Each row will have a start time and end time in 24Hrs format also a checkbox to enable or disable the opening hours for that day, and we have an option to add multiple time slots for every day but time slot can be overlapping. -->
                            <div
                                v-for="(timeSlotDay, dayIndex) in timeSlots[
                                    timeSlotIndex
                                ]['days']"
                                :key="timeSlotDay.id"
                                class="d-flex align-center justify-space-between flex-wrap border my-2 position-relative"
                            >
                                <div class="d-flex align-center flex-wrap">
                                    <v-checkbox
                                        v-model="timeSlotDay.checked"
                                        :label="timeSlotDay.label"
                                        color="primary"
                                        hide-details
                                    ></v-checkbox>
                                    <v-btn
                                        icon="mdi-plus-circle"
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        v-if="timeSlotDay.checked"
                                        @click="addStep(timeSlotDay)"
                                    ></v-btn>
                                    <!-- copy to all -->
                                    <v-btn
                                        prepend-icon="mdi-content-copy"
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        v-if="
                                            timeSlotDay.checked &&
                                            timeSlotDay.id == 1
                                        "
                                        @click="
                                            copyStepsToAll(
                                                timeSlotDay,
                                                timeSlotIndex,
                                            )
                                        "
                                    >
                                        Copy to all
                                    </v-btn>
                                    <v-btn
                                        prepend-icon="mdi-content-copy"
                                        size="small"
                                        color="primary"
                                        variant="text"
                                        v-if="
                                            timeSlotDay.checked &&
                                            timeSlotDay.id != 1
                                        "
                                        @click="
                                            copyPreviousStep(
                                                timeSlotDay,
                                                timeSlotIndex,
                                                dayIndex - 1,
                                            )
                                        "
                                    >
                                        Copy previous
                                    </v-btn>
                                </div>
                                <div
                                    v-if="!timeSlotDay.checked"
                                    class="mr-2 text-overline"
                                >
                                    closed
                                </div>
                                <div
                                    class="d-flex align-center"
                                    v-if="timeSlotDay.checked"
                                >
                                    <div
                                        class="d-flex flex-column align-start align-md-end py-2"
                                    >
                                        <div
                                            v-for="(
                                                step, l
                                            ) in timeSlotDay.steps"
                                            :key="l"
                                        >
                                            <div
                                                class="pr-2 d-flex align-center"
                                            >
                                                <v-btn
                                                    v-if="
                                                        timeSlotDay.steps
                                                            .length > 1
                                                    "
                                                    icon="mdi-delete"
                                                    size="small"
                                                    color="danger"
                                                    variant="text"
                                                    @click="
                                                        removeStep(
                                                            timeSlotDay,
                                                            l,
                                                        )
                                                    "
                                                ></v-btn>
                                                <v-text-field
                                                    style="width: 75px"
                                                    v-model="step.start_time"
                                                    :active="
                                                        step.menu.start_time
                                                    "
                                                    readonly
                                                    variant="outlined"
                                                    hide-details
                                                    density="compact"
                                                >
                                                    <v-menu
                                                        v-model="
                                                            step.menu.start_time
                                                        "
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        activator="parent"
                                                        transition="scale-transition"
                                                    >
                                                        <v-time-picker
                                                            v-if="
                                                                step.menu
                                                                    .start_time
                                                            "
                                                            v-model="
                                                                step.start_time
                                                            "
                                                            full-width
                                                            format="24hr"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-text-field>
                                                <div class="mx-2">-</div>
                                                <v-text-field
                                                    style="width: 75px"
                                                    v-model="step.end_time"
                                                    :active="step.menu.end_time"
                                                    readonly
                                                    variant="outlined"
                                                    hide-details
                                                    density="compact"
                                                >
                                                    <v-menu
                                                        v-model="
                                                            step.menu.end_time
                                                        "
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        activator="parent"
                                                        transition="scale-transition"
                                                    >
                                                        <v-time-picker
                                                            v-if="
                                                                step.menu
                                                                    .end_time
                                                            "
                                                            v-model="
                                                                step.end_time
                                                            "
                                                            full-width
                                                            format="24hr"
                                                        ></v-time-picker>
                                                    </v-menu>
                                                </v-text-field>
                                                <v-checkbox
                                                    :disabled="l != 0"
                                                    width="90px"
                                                    color="success"
                                                    size="small"
                                                    v-model="
                                                        timeSlotDay.traffic
                                                    "
                                                    label="Traffic"
                                                    hide-details
                                                ></v-checkbox>
                                            </div>
                                            <div
                                                v-if="
                                                    isStartBiggerThanEnd(
                                                        step.start_time,
                                                        step.end_time,
                                                    )
                                                "
                                            >
                                                <span
                                                    class="text-caption text-danger"
                                                    >Start time should be
                                                    smaller</span
                                                >
                                            </div>
                                            <v-divider
                                                class="my-2"
                                                v-if="
                                                    l <
                                                    timeSlotDay.steps.length - 1
                                                "
                                            ></v-divider>
                                        </div>
                                        <div
                                            v-if="checkOverlap(timeSlotDay)"
                                            class="text-caption position-absolute top-0 left-0 text-white bg-danger p-2"
                                        >
                                            <v-icon size="small" color="white"
                                                >mdi-alert</v-icon
                                            >
                                            <span
                                                >Time slots are
                                                overlapping</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="timeSlotError"
                            class="text-danger d-flex align-center gap-1"
                        >
                            <v-icon color="danger">mdi-alert</v-icon>
                            {{ timeSlotError }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="closeTimeSlotModal" color="primary">
                            Cancel
                        </v-btn>
                        <v-btn
                            @click="submitForm"
                            prepend-icon="mdi-check"
                            color="primary"
                            :disabled="!isValidTimeSlot"
                        >
                            Apply Timeslot
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        {{ openingHourData[""] }}
        <div class="statusDiv mb-4">
            <v-btn @click="addRecord">Create</v-btn>
        </div>
        <Vue3Datatable
            height="100%"
            :columns="cols"
            :rows="openingHourData['']"
            :sortable="true"
            :stickyHeader="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <button
                        type="button"
                        class="btn btn-outline-success"
                        @click="editRecord(value)"
                    >
                        <i class="bi bi-check-circle"></i>
                        Edit
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deleteLeads(value.id)"
                    >
                        <i class="bi bi-x-circle"></i>
                        Delete
                    </button>
                </div>
            </template>
        </Vue3Datatable>
        <button @click="dialog = true">show</button>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, computed, defineProps, watch, onMounted } from "vue";
import {
    createAdvertiserRevenue,
    updateAdvertiserRevenue,
    deleteAdvertiserRevenue,
    fetchTimeZones,
} from "@/components/advertisers/service.js";
import { useRoute } from "vue-router";
const Route = useRoute();
import { useStore } from "vuex";
const cols = ref([
    { field: "id", title: "ID", width: "90px", filter: false },
    { field: "timezone.name", title: "timezone" },
    { field: "week_days", title: "Week Days" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
function checkOverlap(day) {
    // return timeSlot.some((day) => {
    return day.steps?.some((step, index) => {
        return day.steps?.some((nextStep, nextIndex) => {
            if (index !== nextIndex) {
                return isTimeOverlap(
                    step.start_time,
                    step.end_time,
                    nextStep.start_time,
                    nextStep.end_time,
                );
            }
            return false;
        });
    });
    // });
}
function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
}
function isStartBiggerThanEnd(start, end) {
    return timeToMinutes(start) > timeToMinutes(end);
}
function isTimeOverlap(start1, end1, start2, end2) {
    const start1Time = timeToMinutes(start1);
    const end1Time = timeToMinutes(end1);
    const start2Time = timeToMinutes(start2);
    const end2Time = timeToMinutes(end2);
    return start1Time < end2Time && end1Time > start2Time;
}
const timeSlots = ref([
    {
        name: "Default",
        days: [
            {
                id: 1,
                traffic: false,
                checked: true,
                label: "Monday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 2,
                traffic: false,
                checked: true,
                label: "Tuesday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 3,
                traffic: false,
                checked: true,
                label: "Wednesday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 4,
                traffic: false,
                checked: true,
                label: "Thursday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 5,
                traffic: false,
                checked: true,
                label: "Friday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 6,
                traffic: false,
                checked: true,
                label: "Saturday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
            {
                id: 7,
                traffic: false,
                checked: true,
                label: "Sunday",
                steps: [
                    {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: "00:00",
                        end_time: "23:59",
                    },
                ],
            },
        ],
    },
]);
const timeZone = ref("");
const timeZones = ref([]);
const countries = computed(() => store.getters.getCountries);
const country = ref("");
const timeSlotIndex = ref(0);
const timeSlotsPreviousState = ref(null);
const timeSlotError = ref(null);
// const initialTimeSlot = {
//     name: "Default",
//     days: [
//         {
//             id: 1,
//             checked: true,
//             label: "Monday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 2,
//             checked: true,
//             label: "Tuesday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 3,
//             checked: true,
//             label: "Wednesday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 4,
//             checked: true,
//             label: "Thursday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 5,
//             checked: true,
//             label: "Friday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 6,
//             checked: true,
//             label: "Saturday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//         {
//             id: 7,
//             checked: true,
//             label: "Sunday",
//             steps: [
//                 {
//                     menu: {
//                         start_time: false,
//                         end_time: false,
//                     },
//                     start_time: "00:00",
//                     end_time: "23:59",
//                 },
//             ],
//         },
//     ],
// };
function addStep(timeSlotDay) {
    timeSlotDay.steps.push({
        menu: {
            start_time: false,
            end_time: false,
        },
        start_time: "00:00",
        end_time: "23:59",
    });
}
const isValidTimeSlot = computed(() => {
    const selectedTimeSlot = timeSlots.value[timeSlotIndex.value];
    if (
        selectedTimeSlot.name === "" ||
        !selectedTimeSlot.name ||
        selectedTimeSlot.name.trim() === ""
    ) {
        return false;
    }
    for (let i = 0; i < selectedTimeSlot.days.length; i++) {
        const day = selectedTimeSlot.days[i];
        if (checkOverlap(day)) {
            console.log("overlap");
            return false;
        }
        for (let j = 0; j < day.steps.length; j++) {
            const step = day.steps[j];
            if (isStartBiggerThanEnd(step.start_time, step.end_time)) {
                console.log("start bigger than end");
                return false;
            }
        }
    }
    return true;
});
function closeTimeSlotModal() {
    dialog.value = false;
    timeSlots.value = JSON.parse(JSON.stringify(timeSlotsPreviousState.value));
}
function copyStepsToAll(timeSlotDay, index) {
    timeSlots.value[index].days.forEach((day) => {
        day.steps = JSON.parse(
            JSON.stringify(
                timeSlotDay.steps.map((step) => {
                    return {
                        menu: {
                            start_time: false,
                            end_time: false,
                        },
                        start_time: step.start_time,
                        end_time: step.end_time,
                    };
                }),
            ),
        );
    });
}
function copyPreviousStep(timeSlotDay, timeSlotIndex, dayIndex) {
    const previousSteps = timeSlots.value[timeSlotIndex].days[dayIndex].steps;
    timeSlotDay.steps = JSON.parse(
        JSON.stringify(
            previousSteps.map((step) => {
                return {
                    menu: {
                        start_time: false,
                        end_time: false,
                    },
                    start_time: step.start_time,
                    end_time: step.end_time,
                };
            }),
        ),
    );
}
function removeStep(timeSlotDay, index) {
    timeSlotDay.steps.splice(index, 1);
}
onMounted(async () => {
    timeZones.value = await fetchTimeZones();
});
const Props = defineProps({
    openingHour: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const openingHourData = ref([]);
watch(
    () => Props.openingHour,
    (newVal) => {
        openingHourData.value = newVal;
    },
    { immediate: true },
);
const store = useStore();
const openingHourRef = ref(null);
const form = ref({
    revenue_id: null,
    country_id: "",
    amount: "",
});
const dialog = ref(false);
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await openingHourRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.advertiser_id = Route.params.id;
    if (form.value.revenue_id) {
        // Update Revenue
        await updateAdvertiserRevenue(form.value);
    } else {
        await createAdvertiserRevenue(form.value);
    }
    loading.value = false;
    console.log("Form Data:", form.value);
    dialog.value = false;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deleteAdvertiserRevenue({ revenue_id: id });
                // Display success alert
                if (data) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    openingHourData.value = openingHourData.value.filter(
                        (row) => row.id !== id,
                    );
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    console.log("Edit Record:", record);
    form.value.country_id = record.country.id;
    form.value.amount = record.amount;
    form.value.revenue_id = record.id;
    dialog.value = true;
};
const addRecord = async () => {
    // we reset revenue_id like this because this revenue_id not use in v-form
    // form.value.revenue_id = null;
    dialog.value = true;
    // nextTick(() => {
    //     openingHourRef.value.reset(); // reset form
    //     // openingHourRef.value.resetValidation(); // reset validation only not form
    // });
};
</script>
