<template>
    <div class="">
        <div class="col-md-12">
            <div class="mb-4 pb-2">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'AdvertisersView' }"
                                >Advertisers</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Edit Advertiser
                        </li>
                    </ol>
                </nav>
            </div>
            <!-- Tabs Navigation -->
            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#tab1"
                        >Settings</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab2"
                        >Revenue</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab3"
                        >Distribution</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab4"
                        >Caps</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab5"
                        >Supported Countries</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab6"
                        >Opening Hours</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab7">
                        Exclusions
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab8">
                        Override Affiliate ID's
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab9">
                        API Offer Slug Overrides
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div id="tab1" class="tab-pane fade show active">
                    <!-- Form for Tab 1 -->
                    <div class="box-shadow p-4">
                        <v-form
                            ref="settingFormRef"
                            @submit.prevent="submitForm"
                        >
                            <div class="statusDiv">
                                <v-switch
                                    true-value="active"
                                    color="success"
                                    false-value="inactive"
                                    v-model="form.status"
                                    :label="`Brand is ${form.status}`"
                                ></v-switch>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.name"
                                        label="Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) => !!v || 'Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                                <div class="col-md-6">
                                    <v-text-field
                                        :required="true"
                                        v-model="form.display_name"
                                        label="Display Name"
                                        variant="outlined"
                                        :rules="[
                                            (v) =>
                                                !!v ||
                                                'Display Name is required',
                                        ]"
                                    ></v-text-field>
                                </div>
                            </div>
                            <v-btn
                                @click="submitForm"
                                :loading="loading"
                                color="primary"
                            >
                                submit
                            </v-btn>
                        </v-form>
                    </div>
                </div>
                <div id="tab2" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <RevenueComponent
                            @reloadApi="loadAdvertiser"
                            :revenue="advertiserData.revenues"
                        />
                    </div>
                </div>
                <div id="tab3" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <DistributionComponent />
                    </div>
                </div>
                <div id="tab4" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <CapsComponentComponent
                            @reloadApi="loadAdvertiser"
                            :cap="advertiserData.caps"
                        />
                    </div>
                </div>
                <div id="tab5" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <SupportedCountriesComponent
                            @reloadApi="loadAdvertiser"
                            :country="advertiserData.supported_countries"
                        />
                    </div>
                </div>
                <div id="tab6" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <!-- ss {{ advertiserData.openingHours }} -->
                        <OpenHourComponent
                            :openingHour="advertiserData.openingHours"
                        />
                    </div>
                </div>
                <div id="tab7" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <ExclusionsComponent
                            @reloadApi="loadAdvertiser"
                            :exclusion="advertiserData.exclusions"
                        />
                    </div>
                </div>
                <div id="tab8" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <!-- :overrideAffiliate="advertiserData.exclusions" -->
                        <overrideComponent
                            :override="advertiserData.publisher_overrides"
                        />
                    </div>
                </div>
                <div id="tab9" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <!-- :offerSlugOverride="advertiserData.exclusions" -->
                        <apiOfferSlugOverrideComponent
                            :apiOfferOverRider="advertiserData.funnel_overrides"
                        />
                    </div>
                </div>
                <!-- Similar structure for Tab 2, 3, and 4 -->
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2";
import {
    getAdvertiserById,
    updateAdvertiser,
} from "@/components/advertisers/service";
import overrideComponent from "./overrideAffiliate/overrideComponent.vue";
import ExclusionsComponent from "./exclusions/ExclusionsComponent.vue";
import RevenueComponent from "./revenue/RevenueComponent.vue";
import DistributionComponent from "../distributions/DataTableComponent.vue";
import CapsComponentComponent from "./caps/DataTableComponent.vue";
import SupportedCountriesComponent from "./supportedcountries/DataTableComponent.vue";
import OpenHourComponent from "./openinghours/DataTableComponent.vue";
import apiOfferSlugOverrideComponent from "./offerSlugOverride/offerSlugOverrideComponent.vue";
import { useRoute } from "vue-router";
const Route = useRoute();
const form = reactive({
    display_name: "",
    name: "",
    status: "active",
});
const advertiserData = ref([]);
const loadAdvertiser = async () => {
    const data = await getAdvertiserById(Route.params.id);
    advertiserData.value = await data;
    form.name = await data.advertiser.name;
    form.display_name = await data.advertiser.display_name;
    form.status = await data.advertiser.status;
};
onMounted(async () => {
    await loadAdvertiser();
});
const loading = ref(false);
const settingFormRef = ref(null);
const submitForm = async () => {
    loading.value = true;
    const { valid } = await settingFormRef.value.validate();
    if (valid) {
        form.id = Route.params.id;
        const resp = await updateAdvertiser(form);
        if (!resp) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                confirmButtonText: "Ok",
            });
            loading.value = false;
            return;
        }
        Swal.fire({
            title: "Success",
            text: "Advertiser updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
        });
        loading.value = false;
    }
};
</script>
<style>
.statusDiv {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
}
</style>
