<template>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
        <v-card v-if="page === 1">
            <v-toolbar>
                <v-toolbar-title>New Distribution</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="$emit('close')"></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-list lines="two" subheader>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-cog</v-icon>
                    General Information</v-list-subheader
                >

                <v-list-item>
                    <v-col>
                        <v-text-field
                            v-model="label"
                            label="Label"
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-combobox
                            v-model="tags"
                            :items="[]"
                            label="Tags"
                            multiple
                            return-object
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip
                                    small
                                    @click="tags.splice(index, 1)"
                                    close
                                >
                                    {{ item.title }}
                                </v-chip>
                            </template>
                        </v-combobox>
                    </v-col>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-cog</v-icon>
                    Method</v-list-subheader
                >
                <v-list-item>
                    <v-radio-group
                        v-model="method"
                        inline
                        label="Method"
                        hide-details
                    >
                        <v-radio label="SLOTS" value="slot"></v-radio>
                        <v-radio label="CHANCE" value="chance"></v-radio>
                        <v-radio label="OPTIMIZE" value="optimize"></v-radio>
                    </v-radio-group>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-clock</v-icon>
                    Hours
                </v-list-subheader>

                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="timeZone"
                            :items="timeZones"
                            placeholder="Select a timezone"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            class="ml-n2"
                            v-model="traffic"
                            label="Traffic (Reject leads outside opening hours)"
                            color="primary"
                            hide-details
                        ></v-checkbox>
                    </v-col>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-earth</v-icon>
                    Country
                </v-list-subheader>

                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="country"
                            :items="countries"
                            placeholder="Select a country"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            hide-details
                            clearable
                            density="compact"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Publisher
                </v-list-subheader>

                <v-list-item>
                    <v-col>
                        <v-autocomplete
                            v-model="selectedPublisher"
                            :items="publishers"
                            placeholder="Select publisher"
                            item-title="name"
                            item-value="id"
                            variant="outlined"
                            density="compact"
                            hide-details
                            clearable
                        >
                        </v-autocomplete>
                    </v-col>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Parameters
                </v-list-subheader>

                <v-list-item>
                    <v-col>
                        <div
                            class="d-flex flex-wrap flex-md-nowrap gap-4 justify-center"
                        >
                            <v-autocomplete
                                label="Parameter"
                                class="w-100"
                                v-model="parameterLabel"
                                :items="parameterLabels"
                                placeholder="Select a parameter"
                                item-title="name"
                                return-object
                                variant="outlined"
                                hide-details
                                clearable
                                density="compact"
                            >
                            </v-autocomplete>
                            <v-autocomplete
                                label="Value"
                                class="w-100"
                                v-model="parameterValue"
                                :items="parameterValues"
                                placeholder="Select a value"
                                item-title="name"
                                return-object
                                variant="outlined"
                                hide-details
                                clearable
                                density="compact"
                            >
                            </v-autocomplete>
                            <v-btn
                                icon="mdi-plus"
                                size="small"
                                @click="addParameter"
                                :disabled="!parameterLabel || !parameterValue"
                            ></v-btn>
                        </div>
                    </v-col>
                    <v-col v-if="selectedParameters.length > 0">
                        <v-list>
                            <v-list-item
                                v-for="(parameter, index) in selectedParameters"
                                :key="`parameter-${index}`"
                                class="border"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        parameter.label.name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        parameter.value.name
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <v-btn
                                        icon="mdi-delete"
                                        size="small"
                                        color="error"
                                        variant="text"
                                        @click="removeParameter(index)"
                                    ></v-btn>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col v-else class="text-center"> No parameters </v-col>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-subheader class="text-uppercase">
                    <v-icon>mdi-tune</v-icon>
                    Lead Data Overwrite
                </v-list-subheader>

                <v-list-item>
                    <v-col>
                        <div
                            class="d-flex flex-wrap flex-md-nowrap gap-4 justify-center"
                        >
                            <v-autocomplete
                                label="Parameter"
                                class="w-100"
                                v-model="overwriteLabel"
                                :items="overwriteLabels"
                                placeholder="Select a parameter"
                                item-title="name"
                                return-object
                                variant="outlined"
                                hide-details
                                clearable
                                density="compact"
                            >
                            </v-autocomplete>
                            <v-combobox
                                class="w-100"
                                v-model="overwriteValue"
                                :items="overwriteValues"
                                label="Values"
                                placeholder="Select a value"
                                item-title="name"
                                multiple
                                return-object
                                variant="outlined"
                                hide-details
                                clearable
                                density="compact"
                            >
                                <!-- :rules="[
                                        (value) =>
                                            !!value.length ||
                                            'Please select at least one status',
                                    ]" -->
                                <template v-slot:selection="{ item, index }">
                                    <v-chip
                                        small
                                        @click="overwriteValue.splice(index, 1)"
                                        close
                                    >
                                        {{ item.title }}
                                    </v-chip>
                                </template>
                            </v-combobox>
                            <v-btn
                                icon="mdi-plus"
                                size="small"
                                :disabled="
                                    !overwriteLabel ||
                                    overwriteValue?.length <= 0
                                "
                                @click="addOverwrite"
                            ></v-btn>
                        </div>
                    </v-col>
                    <v-col v-if="selectedOverwrites.length > 0">
                        <v-list>
                            <v-list-item
                                v-for="(overwrite, index) in selectedOverwrites"
                                :key="`overwrite-${index}`"
                                class="border"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        overwrite.label.name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        overwrite.value.join(", ")
                                    }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <template v-slot:append>
                                    <v-btn
                                        icon="mdi-delete"
                                        size="small"
                                        color="error"
                                        variant="text"
                                        @click="removeOverwrite(index)"
                                    ></v-btn>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col v-else class="text-center">
                        No overwriteLabels
                    </v-col>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-btn @click="togglePage" color="primary">Next</v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="page === 2" class="relative">
            <v-toolbar>
                <v-toolbar-title>Distribution Details</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-toolbar-items>
                    <v-btn icon="mdi-close" @click="$emit('close')"></v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-list class="p-0 pb-14">
                <v-tabs v-model="tab" bg-color="primary">
                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="`tab-${index}`"
                        :text="`Timeslot ${index + 1}`"
                        :value="`tab-${index}`"
                    ></v-tab>
                </v-tabs>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item
                        v-for="(tab, i) in tabs"
                        :key="`tab-content-${i}`"
                        :value="`tab-${i}`"
                    >
                        <v-card>
                            <div
                                class="d-flex flex-wrap flex-md-nowrap align-center gap-2 justify-center justify-md-end m-1 m-md-4"
                            >
                                <!-- btns - add new group, remove all groups -->
                                <v-btn
                                    icon
                                    @click="openTimeSlotDialog(i)"
                                    variant="outlined"
                                >
                                    <v-icon>mdi-clock</v-icon>
                                </v-btn>
                                <!-- add timeslot to left & right -->
                                <v-btn
                                    icon
                                    @click="addTabToLeft(i)"
                                    variant="outlined"
                                    color="primary"
                                >
                                    <v-icon style="transform: rotateY(180deg)"
                                        >mdi-clock-start</v-icon
                                    >
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="addTabToRight(i)"
                                    variant="outlined"
                                    color="primary"
                                >
                                    <v-icon>mdi-clock-start</v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    prepend-icon="mdi-plus"
                                    text="Timeslot to right"
                                    @click="addTabToRight(i)"
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                ></v-btn> -->
                                <!-- <v-btn
                                    prepend-icon="mdi-delete"
                                    text="Timeslot"
                                    size="small"
                                    color="danger"
                                    variant="outlined"
                                    @click="removeTab(i)"
                                ></v-btn> -->
                                <v-btn
                                    icon
                                    @click="removeTab(i)"
                                    variant="outlined"
                                    color="danger"
                                >
                                    <v-icon>mdi-delete-clock</v-icon>
                                </v-btn>
                                <v-btn
                                    prepend-icon="mdi-plus"
                                    text="Group"
                                    size="small"
                                    @click="addGroup(i)"
                                    color="success"
                                    variant="outlined"
                                ></v-btn>
                                <v-btn
                                    prepend-icon="mdi-delete"
                                    text="All"
                                    @click="removeAllGroups(i)"
                                    size="small"
                                    color="danger"
                                    variant="outlined"
                                ></v-btn>
                            </div>
                            <template v-if="tab.length <= 0">
                                <v-card-text class="text-center text-caption">
                                    Please add groups to the timeslot
                                </v-card-text>
                            </template>

                            <v-card
                                elevation="0"
                                v-else
                                v-for="(group, j) in tab"
                                :key="`group-${j}`"
                                class="border m-2 m-md-4"
                            >
                                <v-card-actions
                                    class="d-flex flex-wrap flex-md-nowrap align-center gap-2 justify-space-between py-2"
                                >
                                    <div class="w-100">
                                        <v-card-title
                                            class="d-flex justify-center justify-md-start align-center"
                                        >
                                            <!-- Group -
                                            {{ j + 1 }} -->
                                            <v-text-field
                                                v-model="group.name"
                                                label="Group Name"
                                                variant="outlined"
                                                density="compact"
                                                class="d-inline-block"
                                                hide-details
                                                max-width="200px"
                                            ></v-text-field>
                                            &nbsp;
                                            <v-chip
                                                v-if="
                                                    calculateGroupShare(
                                                        group,
                                                    ) === 100
                                                "
                                                color="success"
                                                label
                                            >
                                                <v-icon left
                                                    >mdi-check-bold</v-icon
                                                >
                                                &nbsp;
                                                {{ calculateGroupShare(group) }}
                                            </v-chip>
                                            <v-chip v-else color="error" label>
                                                <v-icon left>mdi-alert</v-icon>
                                                &nbsp;
                                                {{ calculateGroupShare(group) }}
                                            </v-chip>
                                        </v-card-title>
                                    </div>

                                    <div
                                        class="d-flex gap-2 align-center justify-center justify-md-end flex-wrap w-100 mr-0 mr-md-2"
                                    >
                                        <v-btn
                                            prepend-icon="mdi-plus"
                                            text="Advertiser"
                                            size="small"
                                            @click="addBrand(i, j)"
                                            color="success"
                                            variant="outlined"
                                        ></v-btn>
                                        <v-btn
                                            icon="mdi-chevron-up"
                                            size="x-small"
                                            color="primary"
                                            variant="outlined"
                                            @click="moveGroupUp(i, j)"
                                        ></v-btn>
                                        <v-btn
                                            icon="mdi-chevron-down"
                                            size="x-small"
                                            color="primary"
                                            variant="outlined"
                                            @click="moveGroupDown(i, j)"
                                        ></v-btn>

                                        <!-- <v-btn
                                            prepend-icon="mdi-delete"
                                            text="Group"
                                            @click="removeGroup(i, j)"
                                            size="small"
                                            color="danger"
                                            variant="outlined"
                                        ></v-btn> -->
                                        <v-btn
                                            icon
                                            @click="removeGroup(i, j)"
                                            variant="outlined"
                                            color="danger"
                                            size="x-small"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-actions>

                                <v-card-text v-if="group.brands.length > 0">
                                    <div
                                        class="d-flex text-md-center flex-wrap flex-md-nowrap justify-md-space-between justify-center align-center p-3 gap-2 border"
                                        v-for="(brand, k) in group.brands"
                                        :key="`brand-${k}`"
                                    >
                                        <div style="width: 250px">
                                            <v-autocomplete
                                                v-model="brand.data"
                                                :items="brands"
                                                placeholder="Select a brand"
                                                item-title="name"
                                                return-object
                                                variant="outlined"
                                                hide-details
                                                clearable
                                                density="compact"
                                                :rules="[
                                                    (value) =>
                                                        !!value ||
                                                        'Please select a valid brand',
                                                ]"
                                            >
                                                <template v-slot:label>
                                                    <span>Advertiser</span>
                                                </template>
                                                <template
                                                    v-slot:selection="{ item }"
                                                >
                                                    <v-chip small>
                                                        <span>{{
                                                            item.title
                                                        }}</span>
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </div>

                                        <div
                                            style="width: 250px"
                                            class="text-center d-flex align-center justify-center"
                                        >
                                            <v-slider
                                                v-model="brand.percentage"
                                                :max="100"
                                                :min="0"
                                                :step="1"
                                                thumb-label
                                                class="flex-grow-1"
                                                hide-details
                                                color="primary"
                                            >
                                                <template v-slot:append>
                                                </template>
                                            </v-slider>
                                            <v-text-field
                                                v-model="brand.percentage"
                                                density="compact"
                                                style="max-width: 80px"
                                                :max="100"
                                                :min="0"
                                                type="number"
                                                hide-details
                                                variant="outlined"
                                            ></v-text-field>
                                        </div>

                                        <div>
                                            <v-btn
                                                icon="mdi-chevron-up"
                                                size="small"
                                                color="primary"
                                                variant="text"
                                                @click="moveBrandUp(i, j, k)"
                                            ></v-btn>
                                            <v-btn
                                                icon="mdi-chevron-down"
                                                size="small"
                                                color="primary"
                                                variant="text"
                                                @click="moveBrandDown(i, j, k)"
                                            ></v-btn>
                                            <v-btn
                                                icon="mdi-delete"
                                                size="small"
                                                color="danger"
                                                variant="text"
                                                @click="removeBrand(i, j, k)"
                                            ></v-btn>
                                        </div>
                                    </div>
                                </v-card-text>
                                <v-card-text
                                    v-else
                                    class="text-center text-caption"
                                >
                                    Please add brands to this group
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-list>

            <v-card-actions
                class="position-absolute left-0 bottom-0 right-0 bg-white"
            >
                <div
                    v-if="createDistributionError"
                    class="text-danger d-flex align-center gap-1"
                >
                    <v-icon color="danger">mdi-alert</v-icon>
                    {{ createDistributionError }}
                </div>
                <v-btn @click="togglePage" color="primary">Previous</v-btn>
                <v-btn @click="submit" color="primary">Submit</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="timeSlotDialog" persistent max-width="650">
        <v-card>
            <v-card-title> New Timeslot </v-card-title>
            <v-card-text>
                <v-autocomplete
                    v-model="timeZone"
                    :items="timeZones"
                    placeholder="Select a timezone"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    hide-details
                    disabled
                    density="compact"
                >
                </v-autocomplete>
                <v-checkbox
                    class="ml-n2"
                    v-model="traffic"
                    label="Traffic (Reject leads outside opening hours)"
                    color="primary"
                    hide-details
                    disabled
                ></v-checkbox>
                <v-text-field
                    v-model="timeSlots[timeSlotIndex].name"
                    label="Name"
                    variant="outlined"
                    hide-details
                    density="compact"
                    hint="Name that will be used to reference this timeslot"
                ></v-text-field>
                <div class="mt-4">
                    <div class="text-subtitle-1">Opening Hours</div>
                    <!-- It going to contain 7 rows for each day of the week, Each row will have a start time and end time in 24Hrs format also a checkbox to enable or disable the opening hours for that day, and we have an option to add multiple time slots for every day but time slot can be overlapping. -->
                    <div
                        v-for="(timeSlotDay, dayIndex) in timeSlots[
                            timeSlotIndex
                        ]['days']"
                        :key="timeSlotDay.id"
                        class="d-flex align-center justify-space-between flex-wrap border my-2 position-relative"
                    >
                        <div class="d-flex align-center flex-wrap">
                            <v-checkbox
                                v-model="timeSlotDay.checked"
                                :label="timeSlotDay.label"
                                color="primary"
                                hide-details
                                width="120px"
                            ></v-checkbox>
                            <v-btn
                                icon="mdi-plus-circle"
                                size="small"
                                color="primary"
                                variant="text"
                                v-if="timeSlotDay.checked"
                                @click="addStep(timeSlotDay)"
                            ></v-btn>
                            <!-- copy to all -->
                            <v-btn
                                prepend-icon="mdi-content-copy"
                                size="small"
                                color="primary"
                                variant="text"
                                v-if="
                                    timeSlotDay.checked && timeSlotDay.id == 1
                                "
                                @click="
                                    copyStepsToAll(timeSlotDay, timeSlotIndex)
                                "
                            >
                                Copy to all
                            </v-btn>
                            <v-btn
                                prepend-icon="mdi-content-copy"
                                size="small"
                                color="primary"
                                variant="text"
                                v-if="
                                    timeSlotDay.checked && timeSlotDay.id != 1
                                "
                                @click="
                                    copyPreviousStep(
                                        timeSlotDay,
                                        timeSlotIndex,
                                        dayIndex - 1,
                                    )
                                "
                            >
                                Copy previous
                            </v-btn>
                        </div>
                        <div
                            v-if="!timeSlotDay.checked"
                            class="mr-2 text-overline"
                        >
                            closed
                        </div>
                        <div
                            class="d-flex align-center"
                            v-if="timeSlotDay.checked"
                        >
                            <div
                                class="d-flex flex-column align-start align-md-end py-2"
                            >
                                <div
                                    v-for="(step, l) in timeSlotDay.steps"
                                    :key="l"
                                >
                                    <div class="pr-2 d-flex align-center">
                                        <v-btn
                                            v-if="timeSlotDay.steps.length > 1"
                                            icon="mdi-delete"
                                            size="small"
                                            color="danger"
                                            variant="text"
                                            @click="removeStep(timeSlotDay, l)"
                                        ></v-btn>
                                        <v-text-field
                                            style="width: 75px"
                                            v-model="step.start_time"
                                            :active="step.menu.start_time"
                                            readonly
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                        >
                                            <v-menu
                                                v-model="step.menu.start_time"
                                                :close-on-content-click="false"
                                                activator="parent"
                                                transition="scale-transition"
                                            >
                                                <v-time-picker
                                                    v-if="step.menu.start_time"
                                                    v-model="step.start_time"
                                                    full-width
                                                    format="24hr"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-text-field>
                                        <div class="mx-2">-</div>
                                        <v-text-field
                                            style="width: 75px"
                                            v-model="step.end_time"
                                            :active="step.menu.end_time"
                                            readonly
                                            variant="outlined"
                                            hide-details
                                            density="compact"
                                        >
                                            <v-menu
                                                v-model="step.menu.end_time"
                                                :close-on-content-click="false"
                                                activator="parent"
                                                transition="scale-transition"
                                            >
                                                <v-time-picker
                                                    v-if="step.menu.end_time"
                                                    v-model="step.end_time"
                                                    full-width
                                                    format="24hr"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-text-field>
                                    </div>
                                    <div
                                        v-if="
                                            isStartBiggerThanEnd(
                                                step.start_time,
                                                step.end_time,
                                            )
                                        "
                                    >
                                        <span class="text-caption text-danger"
                                            >Start time should be smaller</span
                                        >
                                    </div>
                                    <v-divider
                                        class="my-2"
                                        v-if="l < timeSlotDay.steps.length - 1"
                                    ></v-divider>
                                </div>
                                <div
                                    v-if="checkOverlap(timeSlotDay)"
                                    class="text-caption position-absolute top-0 left-0 text-white bg-danger p-2"
                                >
                                    <v-icon
                                        v-bind="props"
                                        size="small"
                                        color="white"
                                        >mdi-alert</v-icon
                                    >
                                    <span>Time slots are overlapping</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="timeSlotError"
                    class="text-danger d-flex align-center gap-1"
                >
                    <v-icon color="danger">mdi-alert</v-icon>
                    {{ timeSlotError }}
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeTimeSlotModal" color="primary">
                    Cancel
                </v-btn>
                <v-btn
                    @click="applyTimeslot"
                    prepend-icon="mdi-check"
                    color="primary"
                    :disabled="!isValidTimeSlot"
                >
                    Apply Timeslot
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { ref, watch, onMounted, computed } from "vue";
import {
    fetchTimeZones,
    fetchFilterData2,
    validateTimeSlot,
    createDistribution,
    updateDistribution,
} from "./service";
import { useStore } from "vuex";

export default {
    name: "CreateDistributionModal",
    props: {
        pageNumber: {
            type: Number,
            default: 1,
        },
        distributionId: {
            type: String,
            default: () => null,
        },
        distributionData: {
            type: Object,
            default: () => null,
        },
    },
    setup(props, ctx) {
        const store = useStore();
        // start of page 1
        const page = ref(1);
        const dialog = ref(true); // should always be true
        const filterData = ref(null);
        const createDistributionError = ref(null);

        const label = ref("");
        const tags = ref([]);
        const method = ref("slot");

        const timeZones = ref([]);
        const timeZone = ref("");
        const traffic = ref(false);

        const countries = computed(() => store.getters.getCountries);
        const country = ref("");

        const publishers = ref([]);
        const selectedPublisher = ref(null);

        const parameterLabels = ref([]);
        const parameterValues = computed(() => {
            if (filterData.value && parameterLabel.value?.name) {
                return filterData.value[parameterLabel.value["name"]]["data"];
            } else return [];
        });
        const parameterLabel = ref("");
        const parameterValue = ref("");
        const selectedParameters = ref([]);

        const overwriteLabels = ref([]);
        const overwriteValues = ref([]);
        const overwriteLabel = ref("");
        const overwriteValue = ref([]);
        const selectedOverwrites = ref([]);

        function addParameter() {
            // user cannot select the same parameter twice, so remove the selected from the paramLabels
            parameterLabels.value = parameterLabels.value.filter(
                (value) => value.id !== parameterLabel.value.id,
            );
            selectedParameters.value.push({
                label: parameterLabel.value,
                value: parameterValue.value,
            });
            parameterLabel.value = "";
            parameterValue.value = "";
        }

        function removeParameter(index) {
            parameterLabels.value.push(selectedParameters.value[index].label);
            selectedParameters.value.splice(index, 1);
        }

        function addOverwrite() {
            overwriteLabels.value = overwriteLabels.value.filter(
                (value) => value.id !== overwriteLabel.value.id,
            );
            selectedOverwrites.value.push({
                label: overwriteLabel.value,
                value: overwriteValue.value,
            });
            overwriteLabel.value = "";
            overwriteValue.value = [];
        }

        function removeOverwrite(index) {
            overwriteLabels.value.push(selectedOverwrites.value[index].label);
            selectedOverwrites.value.splice(index, 1);
        }

        function togglePage() {
            page.value = page.value !== 1 ? 1 : 2;
        }

        // end of page 1

        async function submit() {
            const step1Payload = {
                taggingSelected: tags.value,
                selectedCountry: {
                    id: country.value,
                },
                selectedTimeZone: {
                    id: timeZone.value,
                },
                labelValue: label.value,
                selectedMethod: method.value,
                isSendInOpHours: traffic.value,
                selectedAffiliate: {
                    id: selectedPublisher.value,
                },
                parameters: Object.fromEntries(
                    selectedParameters.value.map((parameter) => [
                        parameter.label.id,
                        parameter.value.id,
                    ]),
                ),
                data_override: Object.fromEntries(
                    selectedOverwrites.value.map((parameter) => [
                        parameter.label.id,
                        parameter.value,
                    ]),
                ),
            };
            const step2Payload = [];
            for (let i = 0; i < tabs.value.length; i++) {
                const tab = tabs.value[i];
                const timeSlot = timeSlots.value[i];
                step2Payload.push({
                    name: timeSlot["name"],
                    groups: tab.map((group) => {
                        return {
                            name: group.name,
                            brands: group.brands.map((brand) => {
                                console.log(brand);
                                return {
                                    name: brand.data?.name,
                                    percentage: brand.percentage,
                                    id: brand.data?.id,
                                };
                            }),
                        };
                    }),
                    isActive: 1,
                    openingHours: timeSlot["days"].map((day) => {
                        return {
                            id: day.id,
                            checked: day.checked,
                            steps: day.steps.map((step) => {
                                return {
                                    start_time: step.start_time,
                                    end_time: step.end_time,
                                };
                            }),
                        };
                    }),
                });
            }
            const payload = {
                step1: step1Payload,
                step2: step2Payload,
                from: "distribution",
            };

            let data;
            if (props.distributionId) {
                payload.distribution_id = props.distributionId;
                data = await updateDistribution(payload);
            } else {
                data = await createDistribution(payload);
            }

            if (!data) {
                createDistributionError.value = "Failled to add distribution";
            } else {
                ctx.emit("close");
            }
        }

        // Start of page 2
        const tabs = ref([
            // tab
            [
                // Groups
                {
                    name: null,
                    brands: [],
                },
            ],
            // tab
            // [
            //     // Groups
            //     {
            //         name: null,
            //         brands: [
            //             {
            //                 data: {
            //                     id: 1,
            //                     name: "something",
            //                 },
            //                 percentage: 50,
            //             },
            //             {
            //                 data: {
            //                     id: 2,
            //                     name: "something",
            //                 },
            //                 percentage: 100,
            //             },
            //         ],
            //     },
            //     {
            //         name: null,
            //         brands: [],
            //     },
            // ],
        ]);
        const tab = ref(null);
        const brands = ref([]);

        watch(tabs, (newVal) => {
            tab.value = newVal;
        });

        function calculateGroupShare(group) {
            return group.brands.reduce(
                (acc, brand) => acc + Number(brand.percentage),
                0,
            );
        }

        function addTabToLeft(tabIndex) {
            tabs.value.splice(tabIndex, 0, [
                {
                    name: null,
                    brands: [],
                },
            ]);
            tab.value = `tab-${tabIndex}`;
            timeSlots.value.splice(
                tabIndex,
                0,
                JSON.parse(JSON.stringify(initialTimeSlot)),
            );
        }

        function addTabToRight(tabIndex) {
            tabs.value.splice(tabIndex + 1, 0, [
                {
                    name: null,
                    brands: [],
                },
            ]);
            tab.value = `tab-${tabIndex + 1}`;
            timeSlots.value.splice(
                tabIndex + 1,
                0,
                JSON.parse(JSON.stringify(initialTimeSlot)),
            );
        }

        function removeTab(tabIndex) {
            if (tabs.value.length > 1) {
                tabs.value.splice(tabIndex, 1);
                timeSlots.value.splice(tabIndex, 1);
                tab.value = `tab-${tabIndex - 1}`;
            }
        }

        function addGroup(tabIndex) {
            tabs.value[tabIndex].push({
                name: null,
                brands: [],
            });
        }

        function removeAllGroups(tabIndex) {
            tabs.value[tabIndex] = [];
        }

        function removeGroup(tabIndex, groupIndex) {
            tabs.value[tabIndex].splice(groupIndex, 1);
        }

        function moveGroupUp(tabIndex, groupIndex) {
            if (groupIndex > 0) {
                const temp = tabs.value[tabIndex][groupIndex];
                tabs.value[tabIndex][groupIndex] =
                    tabs.value[tabIndex][groupIndex - 1];
                tabs.value[tabIndex][groupIndex - 1] = temp;
            }
        }

        function moveGroupDown(tabIndex, groupIndex) {
            if (groupIndex < tabs.value[tabIndex].length - 1) {
                const temp = tabs.value[tabIndex][groupIndex];
                tabs.value[tabIndex][groupIndex] =
                    tabs.value[tabIndex][groupIndex + 1];
                tabs.value[tabIndex][groupIndex + 1] = temp;
            }
        }

        function addBrand(tabIndex, groupIndex) {
            tabs.value[tabIndex][groupIndex].brands.push({
                data: null,
                percentage: 0,
            });
        }

        function moveBrandUp(tabIndex, groupIndex, brandIndex) {
            if (brandIndex > 0) {
                const temp =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex];
                tabs.value[tabIndex][groupIndex].brands[brandIndex] =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex - 1];
                tabs.value[tabIndex][groupIndex].brands[brandIndex - 1] = temp;
            }
        }

        function moveBrandDown(tabIndex, groupIndex, brandIndex) {
            if (
                brandIndex <
                tabs.value[tabIndex][groupIndex].brands.length - 1
            ) {
                const temp =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex];
                tabs.value[tabIndex][groupIndex].brands[brandIndex] =
                    tabs.value[tabIndex][groupIndex].brands[brandIndex + 1];
                tabs.value[tabIndex][groupIndex].brands[brandIndex + 1] = temp;
            }
        }

        function removeBrand(tabIndex, groupIndex, brandIndex) {
            tabs.value[tabIndex][groupIndex].brands.splice(brandIndex, 1);
        }

        // end of page 2

        // Start of Timeslot Modal
        const timeSlotDialog = ref(false);
        const timeSlotIndex = ref(0);
        const timeSlotsPreviousState = ref(null);
        const timeSlotError = ref(null);
        const initialTimeSlot = {
            name: "Default",
            days: [
                {
                    id: 1,
                    checked: true,
                    label: "Monday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 2,
                    checked: true,
                    label: "Tuesday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 3,
                    checked: true,
                    label: "Wednesday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 4,
                    checked: true,
                    label: "Thursday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 5,
                    checked: true,
                    label: "Friday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 6,
                    checked: true,
                    label: "Saturday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
                {
                    id: 7,
                    checked: true,
                    label: "Sunday",
                    steps: [
                        {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: "00:00",
                            end_time: "23:59",
                        },
                    ],
                },
            ],
        };
        const timeSlots = ref([
            {
                name: "Default",
                days: [
                    {
                        id: 1,
                        checked: true,
                        label: "Monday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 2,
                        checked: true,
                        label: "Tuesday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 3,
                        checked: true,
                        label: "Wednesday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 4,
                        checked: true,
                        label: "Thursday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 5,
                        checked: true,
                        label: "Friday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 6,
                        checked: true,
                        label: "Saturday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                    {
                        id: 7,
                        checked: true,
                        label: "Sunday",
                        steps: [
                            {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: "00:00",
                                end_time: "23:59",
                            },
                        ],
                    },
                ],
            },
        ]);

        function checkOverlap(day) {
            // return timeSlot.some((day) => {
            return day.steps?.some((step, index) => {
                return day.steps?.some((nextStep, nextIndex) => {
                    if (index !== nextIndex) {
                        return isTimeOverlap(
                            step.start_time,
                            step.end_time,
                            nextStep.start_time,
                            nextStep.end_time,
                        );
                    }
                    return false;
                });
            });
            // });
        }

        function timeToMinutes(time) {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes;
        }

        function isStartBiggerThanEnd(start, end) {
            return timeToMinutes(start) > timeToMinutes(end);
        }

        function isTimeOverlap(start1, end1, start2, end2) {
            const start1Time = timeToMinutes(start1);
            const end1Time = timeToMinutes(end1);
            const start2Time = timeToMinutes(start2);
            const end2Time = timeToMinutes(end2);

            return start1Time < end2Time && end1Time > start2Time;
        }

        const isValidTimeSlot = computed(() => {
            const selectedTimeSlot = timeSlots.value[timeSlotIndex.value];
            if (
                selectedTimeSlot.name === "" ||
                !selectedTimeSlot.name ||
                selectedTimeSlot.name.trim() === ""
            ) {
                return false;
            }
            for (let i = 0; i < selectedTimeSlot.days.length; i++) {
                const day = selectedTimeSlot.days[i];
                if (checkOverlap(day)) {
                    console.log("overlap");
                    return false;
                }
                for (let j = 0; j < day.steps.length; j++) {
                    const step = day.steps[j];
                    if (isStartBiggerThanEnd(step.start_time, step.end_time)) {
                        console.log("start bigger than end");
                        return false;
                    }
                }
            }
            return true;
        });

        function openTimeSlotDialog(i) {
            console.log("index", i);
            timeSlotError.value = null;
            timeSlotsPreviousState.value = JSON.parse(
                JSON.stringify(timeSlots.value),
            );
            timeSlotIndex.value = i;
            timeSlotDialog.value = true;
        }

        function addStep(timeSlotDay) {
            timeSlotDay.steps.push({
                menu: {
                    start_time: false,
                    end_time: false,
                },
                start_time: "00:00",
                end_time: "23:59",
            });
        }

        function removeStep(timeSlotDay, index) {
            timeSlotDay.steps.splice(index, 1);
        }

        function copyStepsToAll(timeSlotDay, index) {
            timeSlots.value[index].days.forEach((day) => {
                day.steps = JSON.parse(
                    JSON.stringify(
                        timeSlotDay.steps.map((step) => {
                            return {
                                menu: {
                                    start_time: false,
                                    end_time: false,
                                },
                                start_time: step.start_time,
                                end_time: step.end_time,
                            };
                        }),
                    ),
                );
            });
        }

        function copyPreviousStep(timeSlotDay, timeSlotIndex, dayIndex) {
            const previousSteps =
                timeSlots.value[timeSlotIndex].days[dayIndex].steps;
            timeSlotDay.steps = JSON.parse(
                JSON.stringify(
                    previousSteps.map((step) => {
                        return {
                            menu: {
                                start_time: false,
                                end_time: false,
                            },
                            start_time: step.start_time,
                            end_time: step.end_time,
                        };
                    }),
                ),
            );
        }

        function closeTimeSlotModal() {
            timeSlotDialog.value = false;
            timeSlots.value = JSON.parse(
                JSON.stringify(timeSlotsPreviousState.value),
            );
        }

        async function applyTimeslot() {
            console.log("applyTimeslot");
            const payload = {
                timeslots: timeSlots.value.map((timeSlot) => {
                    return timeSlot.days.map((day) => {
                        return {
                            id: day.id,
                            name: day.label,
                            checked: day.checked,
                            steps: day.steps.map((step) => {
                                return {
                                    start_time: step.start_time,
                                    end_time: step.end_time,
                                };
                            }),
                        };
                    });
                }),
            };
            const data = await validateTimeSlot(payload);
            if (data?.error || !data) {
                console.log("Invalid timeslot");
                timeSlotError.value = "Time already taken";
            } else {
                timeSlotsPreviousState.value = null;
                timeSlotDialog.value = false;
            }
        }

        function dayFromId(id) {
            switch (id) {
                case 1:
                    return "Monday";
                case 2:
                    return "Tuesday";
                case 3:
                    return "Wednesday";
                case 4:
                    return "Thursday";
                case 5:
                    return "Friday";
                case 6:
                    return "Saturday";
                case 7:
                    return "Sunday";
            }
        }
        // End of Timeslot Modal
        onMounted(async () => {
            if (props.pageNumber == 2) {
                page.value = 2;
            }
            console.log(props);
            // fill first page
            if (props.distributionData) {
                label.value = props.distributionData.label;
                tags.value = props.distributionData.tags;
                method.value = props.distributionData.distribution_type;

                timeZone.value = props.distributionData.timezone_id;
                traffic.value = props.distributionData.is_send_in_op_hours == 1;

                country.value = props.distributionData.country_id;
                selectedPublisher.value = props.distributionData.affiliate_id;

                // selectedParameters needs to implement in backend
                // selectedOverwrites needs to implement in backend

                // fill second page
                tabs.value = props.distributionData.step2.map((timeslot) => {
                    return timeslot.groups.map((group) => {
                        return {
                            name: group.name,
                            brands: group.brands.map((brand) => {
                                return {
                                    data: {
                                        id: brand.id,
                                        name: brand.name,
                                    },
                                    percentage: brand.percentage,
                                };
                            }),
                        };
                    });
                });

                timeSlots.value = props.distributionData.step2.map(
                    (timeslot) => {
                        return {
                            name: timeslot.name,
                            days: timeslot.openingHours.map((day) => {
                                return {
                                    id: day.id,
                                    checked: day.checked,
                                    label: dayFromId(day.id),
                                    steps: day.steps?.map((step) => {
                                        return {
                                            menu: {
                                                start_time: false,
                                                end_time: false,
                                            },
                                            start_time: step.start_time
                                                .split(":")
                                                .slice(0, 2)
                                                .join(":"),
                                            end_time: step.end_time
                                                .split(":")
                                                .slice(0, 2)
                                                .join(":"),
                                        };
                                    }),
                                };
                            }),
                        };
                    },
                );
            }

            timeZones.value = await fetchTimeZones();
            filterData.value = await fetchFilterData2();
            for (const parameter in filterData.value) {
                parameterLabels.value.push({
                    name: parameter,
                    id: filterData.value[parameter].id,
                });
                overwriteLabels.value.push({
                    name: parameter,
                    id: filterData.value[parameter].id,
                });
                parameterValues.value = [
                    ...parameterValues.value,
                    ...filterData.value[parameter].data,
                ];
            }
            brands.value = filterData.value["Advertisers"]["data"];
            publishers.value = filterData.value["Publishers"]["data"];
        });

        return {
            // Start of Timeslot Modal
            timeSlotDialog,
            openTimeSlotDialog,
            timeSlots,
            timeSlotsPreviousState,
            timeSlotError,
            timeSlotIndex,
            addStep,
            removeStep,
            copyStepsToAll,
            copyPreviousStep,
            closeTimeSlotModal,
            applyTimeslot,
            checkOverlap,
            isStartBiggerThanEnd,
            isValidTimeSlot,

            // End of Timeslot Modal

            dialog,
            label,
            tags,
            method,
            timeZone,
            timeZones,
            traffic,
            country,
            countries,
            publishers,
            selectedPublisher,
            parameterLabels,
            parameterValues,
            parameterLabel,
            parameterValue,
            selectedParameters,
            overwriteLabels,
            overwriteLabel,
            overwriteValue,
            overwriteValues,
            selectedOverwrites,
            addParameter,
            removeParameter,
            addOverwrite,
            removeOverwrite,
            togglePage,
            page,

            // addTab,
            addTabToLeft,
            addTabToRight,
            tabs,
            tab,
            removeTab,
            addGroup,
            removeAllGroups,
            removeGroup,
            moveGroupUp,
            moveGroupDown,
            addBrand,
            moveBrandUp,
            moveBrandDown,
            removeBrand,
            calculateGroupShare,
            brands,

            submit,
            createDistributionError,
        };
    },
};
</script>

<style></style>
