import axios from "axios";
const getAdvertiserById = async (id) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/${id}/settings`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateAdvertiser = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// api/advertisers/revenue
const createAdvertiserRevenue = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/revenue`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateAdvertiserRevenue = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/revenue`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const deleteAdvertiserRevenue = async (id) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/revenue`;
        const response = await axios.delete(url, id);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchFilterData2 = async () => {
    try {
        const url = `/api/get-filter-data-2`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const fetchTimeZones = async () => {
    try {
        const url = `/api/timezone`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};
// createAdvertiserExclusion,
const createAdvertiserExclusion = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/exclusion`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// updateAdvertiserExclusion,
const updateAdvertiserExclusion = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/exclusion`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// deleteAdvertiserExclusion,
const deleteAdvertiserExclusion = async (id) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/exclusion`;
        const response = await axios.delete(url, id);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// create supported country
// {{host}}/api/advertisers/supported-countries
const createSupportedCountry = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/supported-countries`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// update supported country
// {{host}}/api/advertisers/supported-countries
const updateSupportedCountry = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/supported-countries`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// delete supported country
// {{host}}/api/advertisers/supported-countries
// {
//     "advertiser_id" : "18",
//     "country_id":"3"
//     }
const deleteSupportedCountry = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/advertisers/supported-countries`;
        const response = await axios.delete(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
// {
//     "countries" : [1, 2],
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "funnel" : "example funnel to override",
//     "override_values": []
// }
// API OFFER
const createFunnelOverride = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/funnel-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updateFunnelOverride = async (id, data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/funnel-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/funnel-override
const deleteFunnelOverride = async (id) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/funnel-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
// {
//     "country_id" : 1,
//     "affiliate_id" : 1,
//     "advertiser_id": 1,
//     "override_values": []
// }
//  AFFILIATE API
const createPublisherOverride = async (data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/publisher-override`;
        const response = await axios.post(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
const updatePublisherOverride = async (id, data) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/publisher-override/${id}`;
        const response = await axios.put(url, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// {{host}}/api/publisher-override
const deletePublisherOverride = async (id) => {
    try {
        const url = `${process.env.VUE_APP_API_URL}/api/publisher-override/${id}`;
        const response = await axios.delete(url);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
export {
    fetchTimeZones,
    getAdvertiserById,
    updateAdvertiser,
    createAdvertiserRevenue,
    updateAdvertiserRevenue,
    deleteAdvertiserRevenue,
    fetchFilterData2,
    createAdvertiserExclusion,
    updateAdvertiserExclusion,
    deleteAdvertiserExclusion,
    createSupportedCountry,
    updateSupportedCountry,
    deleteSupportedCountry,
    createFunnelOverride,
    updateFunnelOverride,
    deleteFunnelOverride,
    createPublisherOverride,
    updatePublisherOverride,
    deletePublisherOverride,
};
