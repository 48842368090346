<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editModalLabel">Create Payout</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="handleSubmit">
                    <p class="pb-2">General Information</p>
                    <div class="mb-3">
                        <label for="country" class="form-label">Country</label>
                        <select
                            class="form-select"
                            id="country"
                            v-model="form.country"
                            required
                        >
                            <option value="" disabled>Select Country</option>
                            <option
                                v-for="country in countries"
                                :key="country.id"
                                :value="country.name"
                            >
                                {{ country.name }}
                                <img
                                    :src="country.country_icon"
                                    alt="flag"
                                    class="flag-icon"
                                />
                            </option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="amount" class="form-label">Amount</label>
                        <input
                            type="number"
                            class="form-control"
                            id="amount"
                            v-model="form.amount"
                            required
                        />
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="handleSubmit"
                >
                    Save changes
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const form = ref({
    country: "",
    amount: "",
    role: "",
    password: "",
});

const countries = computed(() => store.getters.getCountries);

// Method to handle form submission
function handleSubmit() {
    console.log("Form Data:", form.value);
    // Add your form submission logic here
}
</script>
