<template>
    <div class="container mt-3">
        <div class="col-md-12">
            <div class="mt-4 mb-4 pb-2 pt-5">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'PublishersView' }"
                                >Publisher</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Create Publisher
                        </li>
                    </ol>
                </nav>
            </div>

            <!-- Tabs Navigation -->

            <!-- Tabs Content -->
            <div class="tab-content box-shadow">
                <div id="tab1" class="tab-pane fade box-shadow show active">
                    <!-- Form for Tab 1 -->
                    <div class="p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                    >
                                        Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Autologin Interval *
                                    </label>
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>Days</option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="manager" class="form-label"
                                        >Locate Leads by *
                                    </label>
                                    <select
                                        class="form-select"
                                        id="manager"
                                        v-model="form.manager"
                                        required
                                    >
                                        <option value="" disabled>
                                            System
                                        </option>
                                        <option
                                            v-for="manager in managers"
                                            :key="manager"
                                            :value="manager"
                                        >
                                            {{ manager }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Tracking Domain
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label
                                        for="advertiser-name"
                                        class="form-label"
                                        >Offer URL</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="advertiser-name"
                                        v-model="form.advertiserName"
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="is-test-advertiser"
                                            v-model="form.isTestAdvertiser"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="is-test-advertiser"
                                        >
                                            Block Duplicate Leads *
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-secondary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-dark"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CreateComponent",
    data() {
        return {
            form: {
                advertiserName: "",
                displayName: "",
                manager: "",
                status: "",
                isTestAdvertiser: false,
            },
            managers: ["Manager 1", "Manager 2", "Manager 3"],
        };
    },
    methods: {
        submitForm() {
            console.log("Form Data:", this.form);
            // Handle form submission logic here
        },
        resetForm() {
            this.form = {
                advertiserName: "",
                displayName: "",
                manager: "",
                status: "",
                isTestAdvertiser: false,
            };
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
