<template>
    <div class="container mt-3">
        <div class="col-md-12">
            <div class="mt-4 mb-4 pb-2 pt-5">
                <nav aria-label="breadcrumb">
                    <ol class="box-shadow breadcrumb bg-light p-4">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'DashboardView' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'PublishersView' }"
                                >Publishers</router-link
                            >
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Edit Publisher
                        </li>
                    </ol>
                </nav>
            </div>

            <!-- Tabs Navigation -->
            <ul class="nav nav-tabs mb-4">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#tab1"
                        >Settings</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab2"
                        >Payout</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab3"
                        >Caps</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab4"
                        >Distributions</a
                    >
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#tab5"
                        >Supported Countries</a
                    >
                </li>

                <!-- Additional tabs as necessary -->
            </ul>

            <div class="tab-content">
                <div id="tab1" class="tab-pane fade show active">
                    <!-- Form for Editing Publisher -->
                    <div class="box-shadow p-4">
                        <form @submit.prevent="submitForm">
                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="publisher-name"
                                        class="form-label"
                                        >Name *</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="publisher-name"
                                        v-model="form.publisherName"
                                        required
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="status" class="form-label"
                                        >Status *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="status"
                                        v-model="form.status"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Status...
                                        </option>
                                        <option value="active">Active</option>
                                        <option value="inactive">
                                            Inactive
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="autologin-interval"
                                        class="form-label"
                                        >Autologin Interval *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="autologin-interval"
                                        v-model="form.autologinInterval"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select Days...
                                        </option>
                                        <option
                                            v-for="interval in intervals"
                                            :key="interval"
                                            :value="interval"
                                        >
                                            {{ interval }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="locate-leads" class="form-label"
                                        >Locate Leads by *</label
                                    >
                                    <select
                                        class="form-select"
                                        id="locate-leads"
                                        v-model="form.locateLeadsBy"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select System...
                                        </option>
                                        <option
                                            v-for="system in systems"
                                            :key="system"
                                            :value="system"
                                        >
                                            {{ system }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <label
                                        for="tracking-domain"
                                        class="form-label"
                                        >Tracking Domain</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="tracking-domain"
                                        v-model="form.trackingDomain"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="offer-url" class="form-label"
                                        >Offer URL</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="offer-url"
                                        v-model="form.offerUrl"
                                    />
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            id="block-duplicate-leads"
                                            v-model="form.blockDuplicateLeads"
                                        />
                                        <label
                                            class="form-check-label"
                                            for="block-duplicate-leads"
                                        >
                                            Block Duplicate Leads *
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6">
                                    <button
                                        type="submit"
                                        class="btn btn-secondary mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-dark"
                                        @click="resetForm"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div id="tab2" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <PayoutComponent />
                    </div>
                </div>

                <div id="tab3" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <CapsComponentComponent />
                    </div>
                </div>

                <div id="tab4" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <DistributionComponent />
                    </div>
                </div>

                <div id="tab5" class="tab-pane fade box-shadow">
                    <div class="box-shadow p-4">
                        <SupportedCountriesComponent />
                    </div>
                </div>

                <!-- Additional content for other tabs -->
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from "vue";
import CapsComponentComponent from "../advertisers/caps/DataTableComponent.vue";
import DistributionComponent from "../distributions/DataTableComponent.vue";
import SupportedCountriesComponent from "../advertisers/supportedcountries/DataTableComponent.vue";
import PayoutComponent from "./payouts/DataTableComponent.vue";
export default {
    name: "EditComponent",
    components: {
        CapsComponentComponent,
        DistributionComponent,
        SupportedCountriesComponent,
        PayoutComponent,
    },
    setup() {
        // Reactive form state
        const form = reactive({
            publisherName: "",
            status: "",
            autologinInterval: "",
            locateLeadsBy: "",
            trackingDomain: "",
            offerUrl: "",
            blockDuplicateLeads: false,
        });

        const intervals = ref(["1 Day", "7 Days", "30 Days"]);
        const systems = ref(["System 1", "System 2", "System 3"]);

        // Methods
        const submitForm = () => {
            console.log(form);
        };

        const resetForm = () => {
            form.publisherName = "";
            form.status = "";
            form.autologinInterval = "";
            form.locateLeadsBy = "";
            form.trackingDomain = "";
            form.offerUrl = "";
            form.blockDuplicateLeads = false;
        };

        return {
            form,
            intervals,
            systems,
            submitForm,
            resetForm,
        };
    },
};
</script>

<style scoped></style>
