<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">{{
                        form.exclusion_id
                            ? "Edit Exclusions"
                            : "Create Exclusions"
                    }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-row> -->
                        <v-form ref="exclusionFormRef">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <!-- {{ form }} -->
                                    <v-combobox
                                        label="Affiliate Name"
                                        variant="outlined"
                                        v-model="form.affiliate_id"
                                        :items="publishers"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) =>
                                                !!v || 'Affiliate is required',
                                        ]"
                                    >
                                    </v-combobox>
                                    <v-combobox
                                        label="Country Name"
                                        variant="outlined"
                                        v-model="form.country_ids"
                                        multiple
                                        :items="countries"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                            (v) =>
                                                v.length > 0 ||
                                                'Country is required',
                                        ]"
                                    >
                                        // "country_ids": [],
                                    </v-combobox>
                                </div>
                                <div class="col-md-12">
                                    <!-- 34.00 -->
                                    <v-combobox
                                        variant="outlined"
                                        v-model="form.funnel"
                                        label="Api Offer"
                                        multiple
                                        item-value="id"
                                        item-title="name"
                                        :return-object="false"
                                        :items="funnelData"
                                        persistent-hint
                                        small-chips
                                    >
                                    </v-combobox>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ exclusionData }} -->
        <div class="statusDiv mb-4">
            <v-btn @click="addRecord">Create</v-btn>
        </div>
        <Vue3Datatable
            height="100%"
            :columns="cols"
            :rows="exclusionData"
            :sortable="true"
            :stickyHeader="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <!-- Advertiser Name -->
            <!-- <template #advertiser_name="{ value }">
                <a
                    :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                    class="text-decoration-underline"
                >
                    {{ value.advertiser_name }}
                    ({{ value.advertiser_id }})
                </a>
            </template> -->
            <!-- Actions -->
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <button
                        type="button"
                        class="btn btn-outline-success"
                        @click="editRecord(value)"
                    >
                        <i class="bi bi-check-circle"></i>
                        Edit
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deleteLeads(value.id)"
                    >
                        <i class="bi bi-x-circle"></i>
                        Delete
                    </button>
                </div>
            </template>
        </Vue3Datatable>
        <button @click="dialog = true">show</button>
    </div>
</template>
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import {
    ref,
    computed,
    defineEmits,
    defineProps,
    onMounted,
    watch,
    nextTick,
} from "vue";
import {
    createAdvertiserExclusion,
    deleteAdvertiserExclusion,
    updateAdvertiserExclusion,
    fetchFilterData2,
} from "@/components/advertisers/service.js";
const Emit = defineEmits(["reloadApi"]);
import { useRoute } from "vue-router";
const Route = useRoute();
import { useStore } from "vuex";
const cols = ref([
    { field: "id", title: "ID", width: "90px", filter: false },
    { field: "advertiser_id", title: "Advertiser ID" },
    { field: "affiliate.name", title: "Affiliate Name" },
    { field: "country.name", title: "Country name" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
const parameterLabels = ref([]);
const parameterValues = ref([]);
const overwriteLabels = ref([]);
const brands = ref([]);
const publishers = ref([]);
const filterData = ref([]);
const funnelData = ref([]);
onMounted(async () => {
    filterData.value = await fetchFilterData2();
    funnelData.value = await filterData.value?.Funnel?.data;
    for (const parameter in filterData.value) {
        parameterLabels.value.push({
            name: parameter,
            id: filterData.value[parameter].id,
        });
        overwriteLabels.value.push({
            name: parameter,
            id: filterData.value[parameter].id,
        });
        parameterValues.value = [
            ...parameterValues.value,
            ...filterData.value[parameter].data,
        ];
    }
    brands.value = await filterData.value["Advertisers"]["data"];
    publishers.value = await filterData.value["Publishers"]["data"]; // affiliate is same as publisher
    console.log(
        "🚀 ~ file: ExclusionsComponent.vue:180 ~ onMounted ~ publishers.value:",
        publishers.value,
    );
});
const Props = defineProps({
    exclusion: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const exclusionData = ref([]);
watch(
    () => Props.exclusion,
    (newVal) => {
        exclusionData.value = newVal;
    },
    { immediate: true },
);
const store = useStore();
const exclusionFormRef = ref(null);
const form = ref({
    // {
    exclusion_id: null,
    advertiser_id: null,
    country_ids: [],
    affiliate_id: null,
    funnel: [], // api offer
});
const dialog = ref(false);
const countries = computed(() => store.getters.getCountries);
// Method to handle form submission
const loading = ref(false);
async function submitForm() {
    const { valid } = await exclusionFormRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.advertiser_id = Route.params.id;
    const affiliateId = form.value.affiliate_id.toString();
    form.value.affiliate_id = affiliateId;
    if (form.value.exclusion_id) {
        const resp = await updateAdvertiserExclusion(form.value);
        if (resp) {
            Emit("reloadApi");
        }
    } else {
        delete form.value.exclusion_id;
        const resp = await createAdvertiserExclusion(form.value);
        if (resp) {
            Emit("reloadApi");
        }
    }
    loading.value = false;
    console.log("Form Data:", form.value);
    dialog.value = false;
}
function deleteLeads(id) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deleteAdvertiserExclusion({
                    exclusion_id: id,
                });
                // Display success alert
                if (data) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    exclusionData.value = exclusionData.value.filter(
                        (row) => row.id !== id,
                    );
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    form.value.exclusion_id = record.id;
    form.value.advertiser_id = record.advertiser_id;
    form.value.country_ids = record.country_ids;
    form.value.affiliate_id = record?.affiliate?.id;
    form.value.funnel = record.funnel;
    dialog.value = true;
};
const addRecord = async () => {
    form.value.advertiser_id = null;
    form.value.exclusion_id = null;
    dialog.value = true;
    nextTick(() => {
        exclusionFormRef.value.reset();
    });
};
</script>
