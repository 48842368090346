<template>
    <div>
        <!-- Dropdown for column visibility -->
        <div
            class="d-flex justify-content-between align-items-center mb-4 col-md-12"
        >
            <div class="dropdown" ref="dropdown">
                <button
                    class="btn-shadow btn btn-dark btn-sm dense"
                    @click="toggleDropdown"
                >
                    Columns
                </button>
                <div
                    v-show="dropdownOpen"
                    class="dropdown-content"
                    style="margin-top: 0px"
                >
                    <label v-for="col in allColumns" :key="col.field">
                        <input
                            type="checkbox"
                            :checked="col.visible"
                            @change="() => toggleColumnVisibility(col.field)"
                        />
                        {{ col.title }}
                    </label>
                </div>
            </div>
            <a @click="createUser()" class="btn btn-primary float-end mb-4">
                <span>+</span>
            </a>
        </div>
        <!-- DataTable Component -->
        <div class="bh-table-responsive table-container mb-5 mt-2">
            <Vue3Datatable
                height="100%"
                :columns="visibleColumns"
                :rows="rows"
                :sortable="true"
                :stickyHeader="true"
                :loading="loading"
                skin="bh-table-bordered bh-table-responsive"
            >
                <!-- Advertiser Name -->
                <template #advertiser_name="{ value }">
                    <a
                        :href="`${apiUrl}/advertisers/${value.advertiser_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.advertiser_name }}
                        ({{ value.advertiser_id }})
                    </a>
                </template>

                <!-- Country -->
                <template #country_name="{ value }">
                    <div class="d-flex items-center gap-2">
                        <!-- Dynamically get the image source based on the country code -->
                        <img
                            v-if="getCountryCode(value.country_name)"
                            :src="`/assets/img/flags/${getCountryCode(value.country_name)}.png`"
                            width="24"
                            class="max-w-none img-flags"
                            alt="country flag"
                            onError="this.src='/img/flags/default.png'"
                        />
                        <div class="text-gray-600">
                            {{ value.country_name }}
                        </div>
                    </div>
                </template>
                <!-- Affiliate Name -->
                <template #affiliate_name="{ value }">
                    <a
                        :href="`${apiUrl}/affiliates/${value.affiliate_id}/setting`"
                        class="text-decoration-underline"
                    >
                        {{ value.affiliate_name }}
                        ({{ value.affiliate_id }})
                    </a>
                </template>

                <!-- Conversion Rate -->
                <template #cr="{ value }"> {{ value.cr }}% </template>

                <!-- Affiliate Conversion Rate -->
                <template #aff_cr="{ value }"> {{ value.aff_cr }}% </template>

                <!-- Brand Status -->
                <template #status="{ value }">
                    <span v-if="value.status === 'active'">
                        <span class="badge bg-success">{{ value.status }}</span>
                    </span>
                    <span v-else>
                        <span class="badge bg-info">{{ value.status }}</span>
                    </span>
                </template>

                <!-- Late FTD -->
                <template #late_ftd="{ value }">
                    <span v-if="value.late_ftd === 'Yes'">
                        <span class="badge bg-success">{{
                            value.late_ftd
                        }}</span>
                    </span>
                    <span v-else>
                        <span class="badge bg-danger">{{
                            value.late_ftd
                        }}</span>
                    </span>
                </template>

                <!-- Actions -->
                <template #actions="{ value }">
                    <div class="d-flex gap-2">
                        <button
                            type="button"
                            class="btn btn-outline-success"
                            @click="editLead(value.id)"
                        >
                            <i class="bi bi-check-circle"></i>
                            Edit
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-danger"
                            @click="deleteLeads(value.id)"
                        >
                            <i class="bi bi-x-circle"></i>
                            Delete
                        </button>
                    </div>
                </template>
            </Vue3Datatable>
        </div>
        <!-- Edit Modal -->
        <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="editModalLabel"
            aria-hidden="true"
            ref="editModal"
        >
            <EditComponent :editId="editId" />
        </div>

        <div
            class="modal fade"
            id="createModal"
            tabindex="-1"
            aria-labelledby="creatModalLabel"
            aria-hidden="true"
            ref="createModal"
        >
            <CreateComponent />
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import EditComponent from "./EditComponent.vue";
import CreateComponent from "./CreateComponent.vue";
import { useStore } from "vuex";
export default {
    name: "DataTableComponent",
    components: {
        Vue3Datatable,
        EditComponent,
        CreateComponent,
    },
    props: {
        filterData: {
            type: Object,
            default: () => ({}),
        },
    },

    setup(props) {
        const store = useStore();
        const apiUrl = computed(() => process.env.VUE_APP_API_URL);

        const allColumns = ref([
            {
                field: "id",
                title: "ID",
                visible: true,
                sortable: true,
            },
            {
                field: "name",
                title: "Name",
                visible: true,
                sortable: true,
            },
            {
                field: "email",
                title: "Email",
                visible: true,
                sortable: true,
            },
            {
                field: "level",
                title: "Level",
                visible: true,
                sortable: true,
            },

            {
                field: "actions",
                title: "Actions",
                visible: true,
            },
        ]);

        const rows = ref([]);
        const dropdownOpen = ref(false);
        const dropdown = ref(null);
        const countries = computed(() => store.getters.getCountries);
        const editId = ref(null);
        const getCountryCode = (countryName) => {
            const country = countries.value.find((c) => c.name === countryName);
            return country ? country.code.toLowerCase() : "default"; // Ensure code is lowercase
        };

        function toggleDropdown() {
            dropdownOpen.value = !dropdownOpen.value;
        }
        const editModal = ref(null);
        const createModal = ref(null);

        function createUser() {
            nextTick(() => {
                if (createModal.value) {
                    const modalInstance = new Modal(createModal.value);
                    modalInstance.show();
                }
            });
        }
        function editLead(id) {
            console.log("Accept Lead: " + id);
            editId.value = id;
            nextTick(() => {
                if (editModal.value) {
                    const modalInstance = new Modal(editModal.value);
                    modalInstance.show();
                }
            });
        }

        function deleteLeads(id) {
            console.log("Reject Lead: " + id);
            Swal.fire({
                title: "Are you sure?",
                text: `You are sure about to delete`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const url = `/api/users/${id}/delete`;

                    try {
                        // Make the POST request with axios
                        const response = await axios.delete(url, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        });
                        // Check if the response has a message
                        if (response.data && response.data.message) {
                            // Display success alert if the response contains the message
                            Swal.fire({
                                icon: "success",
                                title: "Successfully deleted",
                                text: response.data.message,
                            });
                        } else {
                            throw new Error("Unexpected response format");
                        }
                    } catch (error) {
                        // Handle error and display an error alert
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to delete. Please try again.",
                        });

                        console.error("Failed to delete:", error);
                    }
                }
            });
        }

        function toggleColumnVisibility(field) {
            const column = allColumns.value.find((col) => col.field === field);
            if (column) {
                column.visible = !column.visible;
            }
        }

        const visibleColumns = computed(() => {
            return allColumns.value.filter((col) => col.visible);
        });

        const filteredRows = computed(() => {
            return rows.value.filter((row) => {
                return Object.keys(props.filterData.filters || {}).every(
                    (key) => {
                        return (
                            !props.filterData.filters[key] ||
                            row[key] === props.filterData.filters[key]
                        );
                    },
                );
            });
        });

        async function fetchDataWithFilter() {
            const url = `/api/users`;

            console.log("Fetching data from URL:", url);

            try {
                const { data } = await axios.get(url);
                rows.value = data; // Assuming rows is a reactive variable in Vue 3

                console.log("Received data:", data);
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }

        function handleClickOutside(event) {
            if (dropdown.value && !dropdown.value.contains(event.target)) {
                dropdownOpen.value = false;
            }
        }

        onMounted(async () => {
            fetchDataWithFilter();

            document.addEventListener("click", handleClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener("click", handleClickOutside);
        });

        return {
            rows,
            allColumns,
            visibleColumns,
            filteredRows,
            toggleColumnVisibility,
            dropdownOpen,
            dropdown,
            toggleDropdown,
            apiUrl,
            editLead,
            deleteLeads,
            getCountryCode,
            editModal,
            createUser,
            createModal,
            editId,
        };
    },
};
</script>

<style scoped>
.table-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;
}

.dropdown-content label {
    display: block;
    padding: 5px 0;
}

.dropdown-content label:hover {
    background-color: #f1f1f1;
}
.img-flags {
    width: 15px;
    height: 15px;
}
</style>
