<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="editModalLabel">Create User</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                ></button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="handleSubmit">
                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            v-model="form.name"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            v-model="form.email"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label for="role" class="form-label"
                            >Select option</label
                        >
                        <select
                            class="form-select"
                            id="role"
                            v-model="form.role"
                            required
                        >
                            <option value="" disabled>Select Role</option>
                            <option value="operator">Operator</option>
                            <option value="member">Member</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label"
                            >Password</label
                        >
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="form.password"
                            required
                        />
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-dark"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="handleSubmit"
                >
                    Save changes
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

// Define a reactive form object
const form = ref({
    name: "",
    email: "",
    role: "",
    password: "",
    level: 1,
});

// Method to handle form submission
async function handleSubmit() {
    try {
        const response = await axios.post(`/api/users`, form.value);
        console.log("User created successfully:", response.data);

        // Show success alert
        Swal.fire({
            icon: "success",
            title: "Success!",
            text: "User data has been saved successfully!",
            confirmButtonText: "OK",
        });

        // Clear the form after successful submission
        form.value = {
            name: "",
            email: "",
            role: "",
            password: "",
        };
    } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.errors
        ) {
            // Extract and display validation errors
            const errors = Object.values(error.response.data.errors)
                .flat()
                .join(", ");

            // Show error alert with validation messages
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: errors,
                confirmButtonText: "OK",
            });
        } else {
            // Handle general errors
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "There was a problem saving the user data.",
                confirmButtonText: "OK",
            });
        }
    }
}
</script>

<style scoped>
/* Add your custom styles here */
</style>
