<template>
    <div class="row mt-4">
        <div
            class="col-md-2 col-lg-2\ mb-1"
            v-for="(item, index) in cardItems"
            :key="index"
        >
            <div class="card border-primary">
                <div class="card-body text-center text-white bg-primary">
                    <p class="card-text mb-0">{{ item }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GlobalDrillDown",
    data() {
        return {
            cardItems: [
                "Attempts: 1",
                "Leads: 0",
                "Success: 0%",
                "Autologged: X",
                "Autologged Ratio: 0",
                "Total FTDs: 0",
                "Publisher FTDs: 0",
                "Late FTDs: 0",
                "Conversion: 0.00",
                "Aff Conversion: 0.00",
                "eCPL: 0.00",
                "AeCPL: 0",
                "Revenue: 0",
                "Payout: 0",
                "Profit: 0",
            ],
        };
    },
};
</script>

<style scoped>
/* Optional: Additional custom styles can be added here */
.card {
    border-radius: 0.375rem; /* Use Bootstrap's default border radius */
}
.card .card-body,
.card-light .card-body {
    /* padding: 1.25rem; */
    height: 100px;
    align-items: center;
    /* display: flex; */
    align-content: center;
}
</style>
