<template>
    <v-app light>
        <v-app-bar color="indigo-darken-2" prominent>
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
                aria-label="Button for Navbar Menu"
            >
                <v-icon aria-label="menu bar icon">mdi-menu</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <ThemeBtn /> -->
            <template v-slot:append>
                <v-autocomplete
                    v-if="$vuetify.display.mdAndUp"
                    v-model="timezone"
                    :items="timezones"
                    item-title="name"
                    item-value="id"
                    density="compact"
                    variant="outlined"
                    hide-details
                    width="250"
                ></v-autocomplete>
                &nbsp; &nbsp;
                <v-btn icon @click="logout" v-if="$vuetify.display.mdAndUp">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
                <v-menu
                    v-if="$vuetify.display.smAndDown"
                    transition="slide-y-transition"
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ props }">
                        <v-btn icon v-bind="props">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <template v-slot:default>
                                <v-select
                                    v-model="timezone"
                                    :items="timezones"
                                    item-title="name"
                                    item-value="id"
                                    density="compact"
                                    variant="outlined"
                                    hide-details
                                    width="250"
                                ></v-select>
                            </template>
                            <template v-slot:append>
                                <v-btn icon flat @click="logout">
                                    <v-icon>mdi-logout</v-icon>
                                </v-btn>
                            </template>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <v-navigation-drawer
            class="bg-indigo-darken-2"
            v-model="drawer"
            :permanent="$vuetify.display.mdAndUp"
            :temporary="$vuetify.display.mobile"
            :location="$vuetify.display.mobile ? 'left' : undefined"
            :rail="!$vuetify.display.smAndDown && rail"
            @click="rail = false"
        >
            <v-list color="white" theme="dark">
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                    router
                >
                    <template v-slot:prepend>
                        <i :class="item.icon"></i>
                    </template>
                    <template v-slot:title>
                        <span class="ml-3">
                            {{ item.title }}
                        </span>
                    </template>
                </v-list-item>
                <v-list-item v-if="!rail && $vuetify.display.mdAndUp">
                    <template v-slot:append>
                        <v-btn
                            icon="mdi-chevron-left"
                            variant="text"
                            @click.stop="rail = !rail"
                        ></v-btn>
                    </template>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-container fluid>
                <slot></slot>
            </v-container>
        </v-main>
        <!-- <v-footer fixed app>
                <span
                    >&copy; {{ new Date().getFullYear() }} All rights
                    reserved.</span
                >
            </v-footer> -->
    </v-app>
</template>
<script>
import { mapActions } from "vuex"; // Import mapActions
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
export default {
    name: "DefaultLayout",
    data() {
        return {
            rail: false,
            miniVariant: true,
            drawer: true,
            items: [
                {
                    icon: "fas fa-home",
                    title: "Dashboard",
                    to: { name: "DashboardView" },
                },
                {
                    icon: "fas fa-file-alt",
                    title: "Reports",
                    to: "/reports",
                },
                {
                    icon: "fas fa-user-friends",
                    title: "Leads",
                    to: { name: "LeadsView" },
                },
                {
                    icon: "fas fa-exclamation-circle",
                    title: "Pending Conversions",
                    to: { name: "PendingConversionView" },
                },
                {
                    icon: "fas fa-user-tie",
                    title: "Advertisers",
                    to: { name: "AdvertisersView" },
                },
                {
                    icon: "fas fa-share-alt",
                    title: "Distributions",
                    to: { name: "DistributionsView" },
                },
                {
                    icon: "fas fa-users",
                    title: "Publishers",
                    to: { name: "PublishersView" },
                },
                {
                    icon: "fas fa-users-cog",
                    title: "Users",
                    to: { name: "UsersView" },
                },
                {
                    icon: "fas fa-cogs",
                    title: "Settings",
                    to: { name: "SettingsView" },
                },
                {
                    icon: "fas fa-sliders-h",
                    title: "Caps",
                    to: { name: "CapsView" },
                },
                {
                    icon: "fas fa-tasks",
                    title: "Status Group",
                    to: { name: "leadStatusGroupView" },
                },
            ],
        };
    },
    mounted() {
        console.log(process.env.VUE_APP_APP_URL, "App URL"); // Logs the App URL
        console.log(process.env.VUE_APP_API_URL, "API URL"); // Logs the API URL
        console.log(process.env.VUE_APP_NAME, "App Name");
        // Fetch countries and time zones when the component is mounted
        this.fetchCountries();
        this.fetchTimeZones();
    },
    methods: {
        ...mapActions(["fetchCountries", "fetchTimeZones"]), // Map actions to component methods
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const title = computed(() => process.env.VUE_APP_NAME);
        const timezones = computed(() => store.getters.getTimeZones);
        const timezone = ref(null);
        timezone.value = store.getters.getUser.timezone;
        watch(timezone, async (newVal) => {
            await axios.put("/api/users/timezone", {
                timezone_id: newVal,
                user_id: store.getters.getUser.user_id,
            });
            await store.dispatch("setUser", {
                ...store.getters.getUser,
                timezone: newVal,
            });
        });
        async function logout() {
            localStorage.removeItem("user_data");
            store.commit("setUser", null);
            router.push("/login");
        }
        return {
            title,
            logout,
            timezones,
            timezone,
        };
    },
};
</script>
<style scoped>
a:hover {
    color: #fff !important;
}
.v-main {
    max-width: 100vw;
}
</style>
<style>
/* create a global style for the sticky header capitalize for all tables */
thead.bh-sticky {
    text-transform: capitalize;
}
</style>
