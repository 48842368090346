<template>
    <v-row>
        <v-col md="3">
            <FilterComponent />
        </v-col>
        <v-col md="9">
            <DataTableComponent :filterData="filterData" />
        </v-col>
    </v-row>
</template>

<script>
import FilterComponent from "../components/pendingConversions/FilterComponent.vue";
import DataTableComponent from "../components/pendingConversions/DataTableComponent.vue";
import { mapGetters } from "vuex";

export default {
    name: "PendingConversionView",
    components: {
        FilterComponent,
        DataTableComponent,
    },
    computed: {
        ...mapGetters({ filterData: "getFilterData" }),
    },
};
</script>

<style scoped></style>
