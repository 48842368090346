<template>
    <div class="card mt-2 mb-2" style="box-shadow: none">
        <v-data-table
            :headers="columns"
            :items="rows"
            v-model:expanded="expanded"
            class="custom-table"
            item-value="id"
            density="compact"
            hide-default-footer
            :items-per-page="-1"
        >
            <template #[`item.action`]="slotData">
                <button
                    @click="
                        expanded.includes(slotData.item?.id)
                            ? actionColumn(slotData)
                            : openDialog(slotData)
                    "
                >
                    <i
                        :class="
                            expanded.includes(slotData.item?.id)
                                ? 'fa fa-angle-down'
                                : 'fa fa-angle-right'
                        "
                        style="color: #000"
                    ></i>
                </button>
            </template>
            <template #[`item.countries`]="{ item }">
                <span v-if="item.countries">
                    <CountryImg :country_name="item.countries" />
                    <span class="ml-3">{{ item.countries }}</span>
                </span>
            </template>
            <template v-slot:expanded-row="{ item }">
                <tr>
                    <td colspan="100%" class="nested-table-wrapper">
                        <NestedTable
                            v-if="item.showNestedTable"
                            :columns="item.nestedColumns"
                            :rows="item.nestedRows"
                            :drill_down_list="item.drillDownList"
                            :filters="nestedFilters"
                            :tab="tab"
                        />
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" width="auto">
            <v-card width="400">
                <div
                    class="d-flex justify-content-between align-items-center p-2"
                >
                    <div><strong>Show Lead Details</strong></div>
                    <v-btn
                        density="compact"
                        flat
                        icon="mdi-close"
                        @click="dialog = false"
                    ></v-btn>
                </div>
                <hr class="m-0" />
                <div style="max-height: 300px; overflow-y: auto">
                    <v-list
                        :items="drill_down_list"
                        item-title="title"
                        item-value="id"
                        @click:select="handleModalSelecedItem"
                    ></v-list>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import { fetchLeadPivot } from "./service";
import CountryImg from "./CountryImg.vue";

export default {
    props: {
        columns: Array,
        rows: Array,
        drill_down_list: Array,
        filters: Object,
        tab: String,
    },
    components: {
        CountryImg,
    },
    data() {
        return {
            expanded: [],
            dialog: false,
            items: [],
            group_by: "",
            selectedSlotData: null,
            nestedFilters: {},
        };
    },
    methods: {
        openDialog(slotData) {
            this.selectedSlotData = slotData;
            this.dialog = true;
        },
        actionColumn(slotData) {
            const indexExpanded = this.expanded.findIndex(
                (i) => i === slotData.item?.id,
            );
            if (indexExpanded > -1) {
                this.expanded.splice(indexExpanded, 1);
            } else {
                this.expanded.push(slotData.item?.id);
            }
            this.toggleNestedTable(slotData.item);
        },
        async toggleNestedTable(row) {
            if (!row.showNestedTable) {
                await this.fetchNestedTableData(row);
                row.showNestedTable = true;
            } else {
                row.showNestedTable = false;
                row.nestedColumns = [];
                row.nestedRows = [];
            }
        },
        handleModalSelecedItem(item) {
            this.group_by = item?.id;
            this.dialog = false;
            if (this.selectedSlotData) {
                this.actionColumn(this.selectedSlotData);
                this.selectedSlotData = null;
            }
        },
        async fetchNestedTableData(row) {
            if (!this.group_by) return;
            // Create a deep copy of the filters object to avoid modifying the parent data
            const filtersCopy = _.cloneDeep(this.filters); // Or use JSON.parse(JSON.stringify(this.filters)) if you don't use lodash
            const lastParents = filtersCopy?.parents?.at(-1);
            const drillDownObj = {
                [lastParents]: row[lastParents] ? [row[lastParents]] : null,
            };
            const parents = filtersCopy?.parents || [];
            parents.push(this.group_by);
            // Create a new payload object to ensure the data isn't referenced elsewhere
            const payload = {
                ...filtersCopy,
                group_by: this.group_by,
                parents: parents,
                ...drillDownObj,
            };
            // Assign the cloned and modified payload to this.nestedFilters
            this.nestedFilters = payload;
            // Initialize row data for the nested table
            row.nestedRows = [];
            row.nestedColumns = [];
            row.drillDownList = [];
            // Fetch data for the nested table
            const response = await fetchLeadPivot(payload);
            if (!response) return;

            row.nestedRows = this.genrateRows(response?.data || []) || [];
            row.nestedColumns =
                this.generateColumns(response?.headers || []) || [];
            row.drillDownList =
                this.generateDrillDownList(
                    response?.next_group_by_drilldown_enum || [],
                ) || [];
        },
        genrateRows(rows) {
            return rows?.map((row, index) => {
                return {
                    ...row,
                    action: "",
                    id: index + 1,
                    showNestedTable: false,
                };
            });
        },
        generateColumns(headers) {
            let columns = headers?.map((header) => {
                const title = header
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                return {
                    title: title,
                    key: header,
                };
            });
            columns.splice(1, 0, { title: "", key: "action" });
            return columns;
        },
        generateDrillDownList(list) {
            return Object.entries(list)?.map(([id, title]) => ({ id, title }));
        },
    },
};
</script>

<style scoped>
.custom-table {
    background: #fff;
}
.custom-table >>> thead {
    background-color: rgb(246 247 250);
}

.custom-table >>> .v-data-table-header__content {
    font-weight: bold;
    color: #0e1726 !important;
}
.nested-table-wrapper {
    padding: 0;
    margin: 0;
}

.nested-table-wrapper > * {
    width: 100%;
}

.nested-table-wrapper tr {
    width: 100%;
}

.nested-table-wrapper td {
    width: 100%;
}
</style>
