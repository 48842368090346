// src/main.js

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import { createVuetify } from "vuetify";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import "@mdi/font/css/materialdesignicons.css";
import "./axios";

const vuetify = createVuetify({
    components: {
        ...components,
        VTimePicker,
    },
    directives,
});

createApp(App).use(vuetify).use(store).use(router).mount("#app");
