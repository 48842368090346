<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px" persistent>
            <v-card>
                <v-card-title>
                    <span class="headline">{{
                        form.id
                            ? "Edit Supported Country"
                            : "Create Supported Country"
                    }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <!-- <v-row> -->
                        <v-form ref="supportedCountryRef">
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <!-- {{ typeof form.country_id }} -->
                                    <v-combobox
                                        label="Country Name"
                                        variant="outlined"
                                        v-model="form.country_id"
                                        :items="countries"
                                        item-value="id"
                                        :return-object="false"
                                        item-title="name"
                                        :rules="[
                                            (v) => !!v || 'Country is required',
                                        ]"
                                    >
                                    </v-combobox>
                                </div>
                                <div class="col-md-12">
                                    <!-- 34.00 -->
                                    <v-textarea
                                        :required="true"
                                        v-model="form.notes"
                                        rows="2"
                                        label="Note"
                                        variant="outlined"
                                        type="text"
                                        :rules="[
                                            (v) => !!v || 'Note is required',
                                            (v) =>
                                                v.length <= 255 ||
                                                'Note must be less than 255 characters',
                                        ]"
                                    ></v-textarea>
                                </div>
                            </div>
                        </v-form>
                        <!-- </v-row> -->
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="dialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        @click="submitForm"
                        :loading="loading"
                        color="primary"
                    >
                        submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- {{ supportedCountryData }} -->
        <div class="statusDiv mb-4">
            <v-btn @click="addRecord">Create</v-btn>
        </div>
        <Vue3Datatable
            height="100%"
            :columns="cols"
            :rows="supportedCountryData"
            :sortable="true"
            :stickyHeader="true"
            :loading="loading"
            skin="bh-table-bordered bh-table-responsive"
        >
            <template #country_icon="{ value }">
                <img
                    style="width: 40px"
                    :src="value.country_icon"
                    alt="country flag"
                />
            </template>
            <template #actions="{ value }">
                <div class="d-flex gap-2">
                    <!-- <button
                        type="button"
                        class="btn btn-outline-success"
                        @click="editRecord(value)"
                    >
                        <i class="bi bi-check-circle"></i>
                        Edit
                    </button> -->
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        @click="deleteLeads(value)"
                    >
                        <i class="bi bi-x-circle"></i>
                        Delete
                    </button>
                </div>
            </template>
        </Vue3Datatable>
        <button @click="dialog = true">show</button>
    </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import Vue3Datatable from "@bhplugin/vue3-datatable";
import Swal from "sweetalert2";
import { ref, defineEmits, computed, defineProps, watch, nextTick } from "vue";
import {
    createSupportedCountry,
    updateSupportedCountry,
    deleteSupportedCountry,
} from "@/components/advertisers/service.js";
import { useRoute } from "vue-router";
const Route = useRoute();
const dialog = ref(false);
const countries = ref([]);
const loading = ref(false);
import { useStore } from "vuex";
const Emit = defineEmits(["reloadApi"]);
const cols = ref([
    { field: "id", title: "ID", width: "90px", filter: false },
    { field: "country_icon", title: "country flag" },
    { field: "name", title: "Country name" },
    { field: "pivot.notes", title: "Note" },
    { field: "actions", title: "Actions", width: "150px", filter: false },
]);
const store = useStore();
const Props = defineProps({
    country: {
        type: [Object, Array],
        required: true,
        default: () => [],
    },
});
const supportedCountryData = ref([]);
watch(
    () => Props.country,
    (newVal) => {
        supportedCountryData.value = newVal;
        const allCountries = store.getters.getCountries;
        // Filter out countries that exist in newVal
        const filteredCountries = allCountries.filter(
            (country) =>
                !newVal.some(
                    (newValCountry) =>
                        newValCountry.pivot.country_id == country.id,
                ),
        );
        countries.value = filteredCountries;
    },
    { immediate: true },
);
const supportedCountryRef = ref(null);
const form = ref({
    // advertiser_id
    id: null,
    country_id: "",
    notes: "",
});
async function submitForm() {
    const { valid } = await supportedCountryRef.value.validate();
    if (!valid) {
        return;
    }
    loading.value = true;
    form.value.advertiser_id = Route.params.id;
    if (form.value.id) {
        const resp = await updateSupportedCountry(form.value);
        if (resp) {
            Emit("reloadApi");
        }
    } else {
        const resp = await createSupportedCountry(form.value);
        if (resp) {
            Emit("reloadApi");
        }
    }
    loading.value = false;
    console.log("Form Data:", form.value);
    dialog.value = false;
}
function deleteLeads(value) {
    Swal.fire({
        title: "Are you sure?",
        text: `You are sure about to delete`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                // Call delete API
                const data = await deleteSupportedCountry({
                    advertiser_id: Route.params.id,
                    country_id: value.pivot.country_id,
                });
                // Display success alert
                if (data) {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Record deleted successfully.",
                    });
                    supportedCountryData.value =
                        supportedCountryData.value.filter(
                            (row) => row.id !== data.id,
                        );
                }
            } catch (error) {
                // Handle error and display an error alert
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to delete. Please try again.",
                });
                console.error("Failed to delete:", error);
            }
        }
    });
}
const editRecord = (record) => {
    form.value.country_id = record.pivot.country_id;
    form.value.notes = record.pivot.notes;
    form.value.id = record.id;
    dialog.value = true;
};
const addRecord = async () => {
    form.value.id = null;
    dialog.value = true;
    nextTick(() => {
        supportedCountryRef.value.reset(); // reset form
        console.log(
            "🚀 ~ file: DataTableComponent.vue:219 ~ addRecord ~ form.value:",
            form.value,
        );
        // supportedCountryRef.value.resetValidation(); // reset validation only not form
    });
};
</script>
